import React, { useState } from "react";
import "../../Assets/Css/Auth.css";
import Logo from "../../Assets/Images/Logo.svg";
import Form from "react-bootstrap/Form";
import LoginRight from "../../Assets/Images/BankDetail.png";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

const BankDetail = ({ t }) => {
  const [bank, setBank] = useState(""); // State for selected bank
  const [accountType, setAccountType] = useState(""); // State for selected account type

  // Dummy data for banks and account types
  const banks = ["Bank A", "Bank B", "Bank C"];
  const accountTypes = ["Savings", "Checking", "Business"];

  return (
    <div className="container my-5">
      <div className="row  h-100 border-layout">
        <div className="col-lg-6 AuthLeft bg-white py-5">
          <img src={Logo} alt="" width={280} className="mx-5" />

          <div className="d-flex justify-content-center mb-5 fs-2">
            <strong>Bank Details</strong>
          </div>
          <Form className="px-5">
            <Form.Group controlId="validationCustom02" className="mt-3">
              <Form.Label>Select Bank</Form.Label>
              <Form.Select
                onChange={(e) => setBank(e.target.value)}
                value={bank}
              >
                <option value="">Choose...</option>
                {banks.map((bankName, index) => (
                  <option key={index} value={bankName}>
                    {bankName}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group controlId="validationCustom01" className="my-3">
              <Form.Label>Account Number</Form.Label>
              <Form.Control
                required
                type="number"
                placeholder="Account Number"
              />
            </Form.Group>
            <Form.Group controlId="validationCustom02">
              <Form.Label>Account Holder Name</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Account Holder Name"
              />
            </Form.Group>
            <Form.Group controlId="validationCustom03" className="my-3">
              <Form.Label>Account Type</Form.Label>
              <Form.Select
                onChange={(e) => setAccountType(e.target.value)}
                value={accountType}
              >
                <option value="">Choose...</option>
                {accountTypes.map((type, index) => (
                  <option key={index} value={type}>
                    {type}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <button type="submit" className="w-100 mt-3 common-btn fw-bold">
              {t("Add Account")}
            </button>
          </Form>
        </div>
        <div className="col-lg-6 AuthRight p-5 d-flex justify-content-center align-items-center">
          <img src={LoginRight} alt="Login" className="img-fluid" />
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(BankDetail);

BankDetail.propTypes = {
  t: PropTypes.any,
};

import Swal from "sweetalert2";
import { toast } from "react-toastify";

export const confirmSwal = async (
  text,
  subText,
  btnText,
  handleFunc,
  cancelButtonText
) => {
  const result = await Swal.fire({
    title: text,
    text: subText,
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#317d9d",
    cancelButtonColor: "#d33",
    cancelButtonText: cancelButtonText,
    confirmButtonText: btnText,
  });
  if (result.isConfirmed) {
    handleFunc();
  }
};

export const errorToast = (message) => {
  return toast.error(message);
};

export const successToast = (message) => {
  return toast.success(message);
};

export const infoToast = (message) => {
  return toast.info(message);
};

import { del, get, post, getWithExportFile } from "../helpers/apis_helper";

export const updateBalance = (data) => {
  return post("/client/UpdateBalance", data);
};

export const payDuePayment = (data) => {
  return post("/client/service/due/payment", data);
};

export const getSavingServices = (pageNumber, pageSize) => {
  return get(
    `/service/getAllServicesbyClient?pageNumber=${pageNumber}&pageSize=${pageSize}`
  );
};
export const getInvestedSavingServices = (pageNumber, pageSize) => {
  return get(
    `/service/getUserInvestedServices?pageNumber=${pageNumber}&pageSize=${pageSize}`
  );
};

export const savingServiceDetail = (id) => {
  return get(`/service/getInvestedSchemeDetails?serviceId=${id}`);
};

export const getDetailInvestedSavingServices = (id) => {
  return get(`/client/service/detail?serviceId=${id}`);
};

export const getPlanTerminationDetails = (id) => {
  return get(`/service/getTerminateSchemeDetails?serviceId=${id}`);
};

export const buySavingService = (data) => {
  return post("/transaction/buyService", data);
};

export const changePaymentMethodForService = (data) => {
  return post("/client/service/change/paymentmethod", data);
};

export const buySavingServiceWithCard = (data) => {
  return post("/payment-gateway/orders", data);
};
export const buySavingServiceWithExistingCard = (data) => {
  return post("/transaction/buyService/withCard", data);
};
export const saveCardDetails = (data) => {
  return post("/payment-gateway/cards", data);
};
export const saveCardFrameDetails = (data) => {
  return post("/payment-gateway/pagomedios/cards", data);
};
export const rechargeWallet = (data) => {
  return post("/payment-gateway/rechargeWalletApi", data);
};
export const rechargeBalanceWallet = (data) => {
  return post("/payment-gateway/pagomedios/rechargeWalletApi", data);
};
export const postTerminatePlan = (data) => {
  return post("/transaction/terminateServiceTransaction", data);
};
export const withdrawBalance = (data) => {
  return post("/transaction/withdrawWalletAmount", data);
};
export const getCardDetails = () => {
  return get(`/payment-gateway/cards`);
};
export const getFrameCardDetails = () => {
  return get(`/payment-gateway/pagomedios/cards`);
};
export const getCalculatedData = (amount, id) => {
  return get(
    `/service/getSchemeDetailCalculations?amountInvested=${amount}&serviceId=${id}`
  );
};

export const getTransactions = (
  pageNumber,
  pageSize,
  search,
  startDate,
  endDate,
  exportCsv
) => {
  return getWithExportFile(
    `/transaction/getAllClientTransactions?pageNumber=${pageNumber}&pageSize=${pageSize}&filter=${search}&startDate=${startDate}&endDate=${endDate}&exportCSV=${exportCsv}`
  );
};
export const deleteCard = () => {
  return del("/payment-gateway/cards");
};
export const removeAddedCard = (data) => {
  return post("/payment-gateway/pagomedios/cards/delete", data);
};

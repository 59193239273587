import React, { useState, useEffect } from "react";
import "./investTc.css";
import { getCSMinfo } from "../../services/cmsServices.js";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import PageLoader from "../../common/pageLoader.js";

const InvestTc = ({ t }) => {
  const [details, setDetails] = useState();
  const [loading, setLoading] = useState(false);

  const fetch = () => {
    setLoading(true);
    getCSMinfo("term_conditions")
      .then((res) => {
        setDetails(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, "error of getAll products....");
        setLoading(false);
      });
  };

  useEffect(() => {
    fetch();
  }, []);

  return loading ? (
    <PageLoader />
  ) : (
    <>
      <div className="row justify-content-center mt-5">
        <div className="col-8">
          <div className="recent-transaction-div px-2">
            <div className="mb-4">
              {details?.description ? (
                <>
                  <h4 className="my-4"> {details?.title} </h4>
                  <div>
                    <div
                      dangerouslySetInnerHTML={{ __html: details?.description }}
                    ></div>
                  </div>
                </>
              ) : (
                <div>
                  <p>{t("No Content Available")}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withTranslation()(InvestTc);

InvestTc.propTypes = {
  t: PropTypes.any,
};

import React, { useState, useEffect } from "react";
import "./about.css";
import { getCSMinfo } from "../../services/cmsServices.js";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Logo from "../../Assets/Images/Logo.svg";
import Loader from "../../common/pageLoader.js";
import { useNavigate } from "react-router-dom";

const AboutUs = ({ t }) => {
  const language = localStorage.getItem("i18nextLng");
  const [details, setDetails] = useState();
  const [loading, setLoader] = useState(true);
  const navigate = useNavigate();

  const fetch = () => {
    setLoader(true);
    getCSMinfo("about_us")
      .then((res) => {
        setDetails(res?.data);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err, "error of getAll products....");
        setLoader(false);
      });
  };

  useEffect(() => {
    fetch();
    // eslint-disable-next-line
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <>
      <div className="recent-transaction-div mx-5 my-4">
        <div>
          {details?.description ? (
            <>
              <img
                src={Logo}
                alt=""
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/")}
                maxWidth={280}
              />
              <h2 className="mb-4 cms-heading">{details?.title}</h2>
              <div>
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      language === "sp"
                        ? details?.descriptionSp
                        : details?.description,
                  }}
                ></div>
              </div>
            </>
          ) : (
            <div>
              <p>{t("No Content Available")}</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default withTranslation()(AboutUs);

AboutUs.propTypes = {
  t: PropTypes.any,
};

import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import Form from "react-bootstrap/Form";
import * as Yup from "yup";
import "./settings.css";
import { getUser, updateProfile, changePassword } from "../../services/user";
import { errorToast, successToast } from "../../common/toaster";
import Loader from "../../common/loader";
import DummyImage from "../../Assets/Images/dummy.jpg";
import { API_IMAGE_URL } from "../../helpers/apis_helper";
import { FaPencil } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import ChangePassword from "./changePassword";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { successMsgHandler } from "../../common/successMsgHandler";

const ProfileSettings = ({ t }) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [image, setImage] = useState(null);
  const [image1, setImage1] = useState(null);

  const fetch = (pageNumber = 1, pageSize = 10) => {
    // setLoader(true);
    getUser(pageNumber, pageSize)
      .then((res) => {
        setData(res?.data);
        setImage(res?.data?.image ? API_IMAGE_URL + res?.data?.image : "");
        // setLoader(false);
      })
      .catch((err) => {
        console.log(err, "error of getAll products....");
        // setLoader(false);
      });
  };

  useEffect(() => {
    fetch();
  }, []);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: data?.email,
      firstName: data?.firstName,
      lastName: data?.lastName,
      mobileNo: data?.mobileNo,
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Required").email("Invalid email"),
      firstName: Yup.string().required("Required"),
      lastName: Yup.string().required("Required"),
      mobileNo: Yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      try {
        setLoader(true);
        updateProfile({
          image: image1,
          firstName: values?.firstName,
          lastName: values?.lastName,
          mobileNo: values?.mobileNo,
        })
          .then((res) => {
            successToast(t(`${successMsgHandler(res.message)}`));
            setLoader(false);
            fetch();
          })
          .catch((err) => {
            console.log(err, "err");
            errorToast(err?.response?.data?.error);
            setLoader(false);
          });
      } catch (err) {
        setLoader(false);
      }
    },
  });

  const changePasswordValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string().required("Required"),
      newPassword: Yup.string()
        .required("Required")
        .required("Required")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
          "Password must contain at least 8 characters, one uppercase, one lowercase, one number and one special character"
        ),
      confirmPassword: Yup.string()
        .required("Required")
        .oneOf(
          [Yup.ref("newPassword"), null],
          'Must match "New password" field value'
        ),
    }),
    onSubmit: async (values) => {
      try {
        setLoader(true);
        changePassword({
          oldPassword: values?.oldPassword,
          newPassword: values?.newPassword,
        })
          .then((res) => {
            successToast(t(`${successMsgHandler(res.message)}`));
            setLoader(false);

            setTimeout(() => {
              localStorage.removeItem("fintech-user");
              navigate("/login");
            }, 2000);
          })
          .catch((err) => {
            console.log(err, "err");
            errorToast(err?.response?.data?.error);
            setLoader(false);
          });
      } catch (err) {
        setLoader(false);
      }
    },
  });

  const uploadImage = async (e) => {
    setImage1(e?.target?.files?.[0]);
    let File = URL.createObjectURL(e?.target?.files?.[0]);
    setImage(File);
  };

  return (
    <>
      {loader ? <Loader /> : ""}
      <div className="recent-transaction-div mt-5">
        <div className="container ">
          <div className="h-100 border-layout py-4 p-3 p-md-5">
            <h1 className="text-uppercase montserrat-bold letter-2 f-22 mb-3">
              {t("Update Profile")}
            </h1>
            <div className="profileAvatarDiv">
              <label
                htmlFor="test"
                className={`userImg pointer position-relative mx-auto`}
              >
                <input
                  className="d-none"
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  id="test"
                  onChange={(e) => e.target?.files[0] && uploadImage(e)}
                />
                <img
                  className="test"
                  src={image ? image : DummyImage}
                  alt="custom-pic"
                  width={100}
                  height={100}
                />
                <span
                  className={`profileChangePencil position-absolute d-flex align-items-center justify-content-center`}
                >
                  <FaPencil />
                </span>
              </label>
            </div>
            <Form onSubmit={validation.handleSubmit} className="row mt-5">
              <Form.Group
                controlId="validationCustom01"
                className="col-md-6 mb-3"
              >
                <Form.Label className="montserrat-bold">
                  {t("First Name")}
                </Form.Label>
                <Form.Control
                  name="firstName"
                  value={validation.values.firstName}
                  onChange={validation.handleChange}
                  type="text"
                  placeholder=""
                />
                {validation.touched.firstName && validation.errors.firstName ? (
                  <div style={{ color: "red" }}>
                    {validation.errors.firstName}
                  </div>
                ) : null}
              </Form.Group>
              <Form.Group
                controlId="validationCustom01"
                className="col-md-6 mb-3"
              >
                <Form.Label className="montserrat-bold">
                  {t("Last Name")}
                </Form.Label>
                <Form.Control
                  name="lastName"
                  value={validation.values.lastName}
                  onChange={validation.handleChange}
                  type="text"
                  placeholder=""
                />
                {validation.touched.lastName && validation.errors.lastName ? (
                  <div style={{ color: "red" }}>
                    {validation.errors.lastName}
                  </div>
                ) : null}
              </Form.Group>
              <Form.Group
                controlId="validationCustom01"
                className="col-md-6 mb-3"
              >
                <Form.Label className="montserrat-bold">
                  {t("Email")}
                </Form.Label>
                <Form.Control
                  name="email"
                  disabled
                  value={validation.values.email}
                  onChange={validation.handleChange}
                  type="text"
                  placeholder=""
                />
                {validation.touched.email && validation.errors.email ? (
                  <div style={{ color: "red" }}>{validation.errors.email}</div>
                ) : null}
              </Form.Group>
              <Form.Group
                controlId="validationCustom01"
                className="col-md-6 mb-3"
              >
                <Form.Label className="montserrat-bold">
                  {t("Mobile Number")}
                </Form.Label>
                <Form.Control
                  name="mobileNo"
                  value={validation.values.mobileNo}
                  onChange={validation.handleChange}
                  type="text"
                  placeholder=""
                />
                {validation.touched.mobileNo && validation.errors.mobileNo ? (
                  <div style={{ color: "red" }}>
                    {validation.errors.mobileNo}
                  </div>
                ) : null}
              </Form.Group>
              <div className="mt-4 text-center">
                <button type="submit" className="common-btn px-2 fw-bold">
                  {t("Update")}
                </button>
              </div>
            </Form>
          </div>
        </div>
        <ChangePassword validation={changePasswordValidation} />
      </div>
    </>
  );
};

export default withTranslation()(ProfileSettings);

ProfileSettings.propTypes = {
  t: PropTypes.any,
};

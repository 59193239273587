import React, { useContext } from "react";
import Modal from "react-bootstrap/Modal";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { UserContext } from "../../context";
import { updateBalance } from "../../services/cardServices";
import { successToast, errorToast } from "../../common/toaster";

const PaypalModal = ({ show, handleClose, amount }) => {
  const { getUserData } = useContext(UserContext);
  const onError = (data, actions) => {
    console.error("paypal error");
  };
  const onApprove = async (data, actions) => {
    return actions.order.capture().then(function (details) {
      // setShowmetapayment(false);
      // const { payer, id, purchase_units } = details;

      try {
        updateBalance({
          balance: amount,
          transactionAmount: amount,
          transactionType: "Deposit",
          paymentMedium: "Card",
        })
          .then((res) => {
            successToast("Balance updated successfully");
            handleClose();
            getUserData();
          })
          .catch((err) => {
            console.log(err, "err");
          });
      } catch (err) {
        console.log(err, "err");
        errorToast(err);
      }
    });
  };

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={handleClose}
    >
      <Modal.Header closeButton className="montserrat-bold">
        <h5>Add balance to your wallet</h5>
      </Modal.Header>
      <Modal.Body>
        <PayPalScriptProvider
          options={{
            "client-id":
              "AR_nHpzQnJyA8cWZOaM-MWl1rn_tVAx28QEiBNm9NJ6vcxsIDbTfsbtuZHhLmQKWnYVlgQZSrXAHqhkd",
            currency: "USD",
          }}
        >
          {amount && amount > 0 && (
            <PayPalButtons
              disabled={amount < 1}
              onError={onError}
              style={{ layout: "horizontal", tagline: "false" }}
              onApprove={onApprove}
              createOrder={(data, actions) => {
                return actions.order.create({
                  purchase_units: [
                    {
                      amount: {
                        value: amount,
                      },
                    },
                  ],
                });
              }}
            />
          )}
        </PayPalScriptProvider>
      </Modal.Body>
    </Modal>
  );
};

export default PaypalModal;

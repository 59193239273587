import { useState } from "react";
import "../../Assets/Css/Auth.css";
import Logo from "../../Assets/Images/Logo.svg";
import Form from "react-bootstrap/Form";
import LoginRight from "../../Assets/Images/Login.png";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Loader from "../../common/loader";
import { login } from "../../services/register";
import { useFormik } from "formik";
import * as Yup from "yup";
import PasswordTypeComp from "../Common/PasswordTypeComp";
import { errorToast } from "../../common/toaster";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { errorMsgHandler } from "../../common/errorMsgHandler";
import LanguageDropdown from "../Header/LanguageDropdown";
// import { apiHeader } from "../../helpers/apis_helper";

const LoginPage = ({ t }) => {
  const [loader, setLoader] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const navigate = useNavigate();

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required(t("Required")).email(t("Invalid email")),
      password: Yup.string().required(t("Required")),
    }),
    onSubmit: async (values) => {
      setLoader(true);
      login({ emailOrPhone: values?.email, password: values?.password })
        .then((res) => {
          localStorage.setItem("fintech-user", JSON.stringify(res?.data));
          // apiHeader();
          res?.data?.user?.isKYCConfirmed === "APPROVED"
            ? navigate("/select-purpose")
            : navigate("/user-kyc");
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
          errorToast(t(`${errorMsgHandler(err)}`));
        });
    },
  });

  return (
    <div className="container mt-5">
      {loader ? <Loader /> : ""}
      <div>
        <div className="row h-100 border-layout">
          <div className="col-lg-6 AuthLeft bg-white py-5">
            <img
              src={Logo}
              alt=""
              style={{ cursor: "pointer", maxWidth: "280px" }}
              onClick={() => navigate("/")}
              className="mx-5 "
            />

            <div className="d-flex justify-content-center my-5 fs-2 align-items-center">
              <strong className="montserrat-bold">{t("Welcome")}</strong>
            </div>
            <Form onSubmit={validation.handleSubmit} className="px-5">
              <Form.Group controlId="validationCustom01">
                <Form.Label className="montserrat-bold">
                  {t("Email ID")}
                </Form.Label>
                <Form.Control
                  value={validation.values.email}
                  onChange={validation.handleChange}
                  name="email"
                  type="text"
                  placeholder={t("Email Address")}
                />
                {validation.touched.email && validation.errors.email ? (
                  <div style={{ color: "red" }}>{validation.errors.email}</div>
                ) : null}
              </Form.Group>
              <Form.Group
                controlId="validationCustom02"
                className="mt-3 position-relative"
              >
                <Form.Label className="montserrat-bold">
                  {t("Password")}
                </Form.Label>
                <Form.Control
                  value={validation.values.password}
                  onChange={validation.handleChange}
                  name="password"
                  type={passwordType}
                  placeholder={t("Password")}
                />
                <PasswordTypeComp
                  passwordType={passwordType}
                  setPasswordType={setPasswordType}
                />
                {validation.touched.password && validation.errors.password ? (
                  <div style={{ color: "red" }}>
                    {validation.errors.password}
                  </div>
                ) : null}
              </Form.Group>
              <div className="d-flex justify-content-end my-3">
                <Link
                  to={"/forgot-password"}
                  className="fs-6 themeText montserrat-bold"
                >
                  {t("Forgot Password?")}
                </Link>
              </div>

              <button
                type="submit"
                disabled={loader}
                className="w-100 mb-3 common-btn fw-bold"
              >
                {t("Log In")}
              </button>

              <div className="d-flex montserrat-bold justify-content-center">
                <span className="fs-6">
                  {t("Don’t have an account?")}{" "}
                  <Link to={"/signup"} className="themeText">
                    {t("Sign Up")}
                  </Link>
                </span>
              </div>
            </Form>
            <div className="text-center">
              <strong>Language: </strong>
              <LanguageDropdown />
            </div>
          </div>
          <div className="col-lg-6 AuthRight p-5 d-flex justify-content-center align-items-center">
            <img
              src={LoginRight}
              style={{ width: "100%" }}
              alt="Login"
              className=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(LoginPage);

LoginPage.propTypes = {
  t: PropTypes.any,
};

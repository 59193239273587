import React, { useState } from "react";
import "./support.css";
import Loader from "../../common/loader";
import { useFormik } from "formik";
import Form from "react-bootstrap/Form";
import * as Yup from "yup";
import { errorToast, successToast } from "../../common/toaster";
import { raiseQuery } from "../../services/otherServices";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

const Support = ({ t }) => {
  const [loader, setLoader] = useState(false);
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      name: "",
      subject: "",
      query: "",
    },

    validationSchema: Yup.object({
      email: Yup.string().required(t("Required")).email(t("Invalid email")),
      name: Yup.string().required(t("Required")),
      subject: Yup.string().required(t("Required")),
      query: Yup.string().required(t("Required")),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        setLoader(true);
        raiseQuery({
          subject: values?.subject,
          name: values?.name,
          email: values?.email,
          message: values?.query,
        })
          .then((res) => {
            successToast(t("Query raised successfully"));
            setLoader(false);
            resetForm();
          })
          .catch((err) => {
            console.log(err, "err");
            errorToast(err?.response?.data?.error);
            setLoader(false);
          });
      } catch (err) {
        setLoader(false);
      }
    },
  });

  return (
    <>
      {loader ? <Loader /> : ""}
      <div className="recent-transaction-div mt-5">
        <div className="container ">
          <div className="h-100 border-layout p-5">
            <Form onSubmit={validation.handleSubmit} className="row">
              <Form.Group
                controlId="validationCustom01"
                className="col-md-6 mb-3"
              >
                <Form.Label className="montserrat-bold">{t("Name")}</Form.Label>
                <Form.Control
                  name="name"
                  value={validation.values.name}
                  onChange={validation.handleChange}
                  type="text"
                  placeholder={t("Name")}
                />
                {validation.touched.name && validation.errors.name ? (
                  <div style={{ color: "red" }}>{validation.errors.name}</div>
                ) : null}
              </Form.Group>

              <Form.Group
                controlId="validationCustom01"
                className="col-md-6 mb-3"
              >
                <Form.Label className="montserrat-bold">
                  {t("Email")}
                </Form.Label>
                <Form.Control
                  name="email"
                  value={validation.values.email}
                  onChange={validation.handleChange}
                  type="text"
                  placeholder={t("Email Address")}
                />
                {validation.touched.email && validation.errors.email ? (
                  <div style={{ color: "red" }}>{validation.errors.email}</div>
                ) : null}
              </Form.Group>
              <Form.Group
                controlId="validationCustom01"
                className="col-12 mb-3"
              >
                <Form.Label className="montserrat-bold">
                  {t("Subject")}
                </Form.Label>
                <Form.Control
                  name="subject"
                  value={validation.values.subject}
                  onChange={validation.handleChange}
                  type="text"
                  placeholder={t("Subject")}
                />
                {validation.touched.subject && validation.errors.subject ? (
                  <div style={{ color: "red" }}>
                    {validation.errors.subject}
                  </div>
                ) : null}
              </Form.Group>
              <Form.Group
                controlId="validationCustom01"
                className="col-12 mb-3"
              >
                <Form.Label className="montserrat-bold">
                  {t("Query")}
                </Form.Label>
                <Form.Control
                  name="query"
                  value={validation.values.query}
                  rows={10}
                  onChange={validation.handleChange}
                  as="textarea"
                  placeholder={`${t("Write your query")}...`}
                />
                {validation.touched.query && validation.errors.query ? (
                  <div style={{ color: "red" }}>{validation.errors.query}</div>
                ) : null}
              </Form.Group>
              <div className="mt-4 text-center">
                <button type="submit" className="common-btn px-2 fw-bold">
                  {t("Submit")}
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default withTranslation()(Support);

Support.propTypes = {
  t: PropTypes.any,
};

import "../../Assets/Css/Auth.css";
import Logo from "../../Assets/Images/Logo.svg";
import { Card } from "antd";
import banking from "../../Assets/Images/TraditionalBanking.png";
import remitance from "../../Assets/Images/remitance.png";
import service from "../../Assets/Images/service.png";
import crowd from "../../Assets/Images/crowd.png";
import investor from "../../Assets/Images/crowd.png";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const Purpose = ({ t }) => {
  const navigate = useNavigate();
  const handleSubmit = () => {
    navigate("/programmed-service");
  };
  return (
    <div className="container mt-5 mb-5">
      <div className="row justify-content-center align-items-center h-100 border-layout">
        <div style={{ width: "280px" }} className="mt-4">
          <img src={Logo} alt="" className="img-fluid" />
        </div>

        <div className="d-flex justify-content-center mb-5 fs-2">
          <strong className="montserrat-bold">
            {t("Select Your Purpose")}
          </strong>
        </div>
        <div className="row">
          <div className="col-md-3 mb-4  text-center montserrat-regular">
            <Card bordered={false} className="purpose-card active">
              <div className="d-flex justify-content-center mb-2">
                <img
                  src={service}
                  alt=""
                  className="mx-auto bg-light border rounded p-2"
                />
              </div>
              <h6>{t("Programmed Savings Service")}</h6>
              <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit</p>
            </Card>
          </div>
          <div className="col-md-3 mb-4  text-center montserrat-regular">
            <Card bordered={false} className="purpose-card">
              <div className="d-flex justify-content-center mb-2">
                <img
                  src={remitance}
                  alt=""
                  className="mx-auto bg-light border rounded p-2"
                />
              </div>
              <h6>{t("Remittance")}</h6>
              <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit</p>
            </Card>
          </div>
          <div className="col-md-3 mb-4  text-center montserrat-regular">
            <Card bordered={false} className="purpose-card">
              <div className="d-flex justify-content-center mb-2">
                <img
                  src={banking}
                  alt=""
                  className="mx-auto bg-light border rounded p-2"
                />
              </div>
              <h6>{t("Traditional Banking")}</h6>
              <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit</p>
            </Card>
          </div>
          <div className="col-md-3 mb-4  text-center montserrat-regular">
            <Card bordered={false} className="purpose-card">
              <div className="d-flex justify-content-center mb-2">
                <img
                  src={crowd}
                  alt=""
                  className="mx-auto bg-light border rounded p-2"
                />
              </div>
              <h6>{t("Crowd funding Entrepreneurs")}</h6>
              <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit</p>
            </Card>
          </div>
          <div className="col-md-3 mb-4  text-center montserrat-regular">
            <Card bordered={false} className="purpose-card">
              <div className="d-flex justify-content-center mb-2">
                <img
                  src={investor}
                  alt=""
                  className="mx-auto bg-light border rounded p-2"
                />
              </div>
              <h6>{t("Crowd funding-Investor")}</h6>
              <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit</p>
            </Card>
          </div>
        </div>
        <div className="d-flex justify-content-center mt-3 mb-2">
          <button
            type="submit"
            onClick={() => handleSubmit()}
            className="mb-3 common-btn fw-bold px-4"
          >
            {t("Continue")} <ArrowRightOutlined />
          </button>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(Purpose);

Purpose.propTypes = {
  t: PropTypes.any,
};

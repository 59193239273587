import React from "react";
import { UncontrolledTooltip } from "reactstrap";

const Tooltip = ({ placement, target, text }) => {
  return (
    <UncontrolledTooltip placement={placement} target={target}>
      {text}
    </UncontrolledTooltip>
  );
};

export default Tooltip;

/* eslint-disable */
import React from "react";
import { Link, NavLink } from "react-router-dom";
import LogoWhite from "../../Assets/Images/Logo-White.png";
import "./Layout.css";
import PropTypes from "prop-types";
import { GrSend } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import { IoBagCheckOutline } from "react-icons/io5";
import { TbLogout2 } from "react-icons/tb";
import { BsInfoCircle } from "react-icons/bs";
import { GoPulse } from "react-icons/go";
import { MdContactSupport } from "react-icons/md";
import { confirmSwal } from "../../common/toaster";
import { FiEye } from "react-icons/fi";
import { BsClipboard2Data } from "react-icons/bs";
import { IoSettingsOutline } from "react-icons/io5";
import { withTranslation } from "react-i18next";

const routes = [
  // {
  //   path: "/traditional-banking",
  //   name: "Traditional Banking",
  //   icon: <CiBank />,
  // },
  {
    path: "/programmed-service",
    name: "Programmed Service",
    icon: <GrSend />,
  },
  {
    path: "/purchased-services",
    name: "Purchased Services",
    icon: <IoBagCheckOutline />,
  },
  // {
  //   path: "/withdraw",
  //   name: "Withdraw",
  //   icon: <PiHandCoinsDuotone />,
  // },
  // {
  //   path: "/deposit",
  //   name: "Deposit",
  //   icon: <PiCoinsLight />,
  // },
  // {
  //   path: "/project-promotor",
  //   name: "Project Promoter",
  //   icon: <CiUser />,
  // },
  {
    path: "/transactions",
    name: "Transactions",
    icon: <GoPulse />,
  },

  {
    path: "/support",
    name: "Support",
    icon: <MdContactSupport />,
  },
  {
    path: "/profile-settings",
    name: "Profile Settings",
    icon: <IoSettingsOutline />,
  },
];

const Sidebar = ({ t, handleClose }) => {
  const navigate = useNavigate();

  const logoutFunc = () => {
    localStorage.removeItem("fintech-user");
    navigate("/home");
  };

  const handleLogout = () => {
    confirmSwal(
      `${t("Are you sure?")}`,
      `${t("You want to Log Out?")}`,
      `${t("Yes, Logout it!")}`,
      logoutFunc,
      `${t("Cancel")}`
    );
  };

  return (
    <>
      <div className="sidebar-box" onClick={handleClose}>
        <Link className="d-lg-block d-none" to="/login">
          <img
            src={LogoWhite}
            alt=""
            width={300}
            className="px-4 pt-5 img-fluid"
          />
        </Link>
        <div className="user-info d-flex align-items-center mt-xl-5 mt-4 mb-4"></div>
        <ul className="pages-links">
          {routes.map((route, i) => (
            <li key={i} className={`position-relative my-2`}>
              <NavLink to={route.path} key={route.name}>
                <span className="link-icon align-middle">{route.icon}</span>
                <label
                  className="link-name align-middle ps-2 pointer"
                  style={{ cursor: "pointer" }}
                >
                  {t(route.name)}
                </label>
              </NavLink>
            </li>
          ))}
          <li className={`position-relative my-2`}>
            <a
              onClick={handleLogout}
              style={{ cursor: "pointer", color: "white" }}
            >
              <span className="link-icon align-middle">
                <TbLogout2 />
              </span>
              <label
                style={{ cursor: "pointer" }}
                className="link-name align-middle ps-2 pointer"
              >
                {t("Logout")}
              </label>
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default withTranslation()(Sidebar);

Sidebar.propTypes = {
  t: PropTypes.any,
};

import React from 'react'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
const chart = () => {
    let data = []
  const  content= [
    { tag: 'Tag1', value: 10 },
    { tag: 'Tag2', value: 20 },
    { tag: 'Tag3', value: 30 },
    { tag: 'Tag4', value: 40 },
    { tag: 'Tag5', value: 60 },
    { tag: 'Tag4', value: 40 },
    { tag: 'Tag5', value: 20 },
  ] || [];
  content.forEach(({ tag, value }) => {
    data.push([tag, value * 1]);
  });
  const options = {
    chart: {
      type: 'areaspline',
      backgroundColor: '',
      options3d: {
        enabled: true,
        alpha: 45, // Angle of the pie chart
        beta: 0, // Perspective angle
        depth: 30
      }
    },
    title: {
      text: ''
    },
    series: [{
      name: 'Categories',
      colorByPoint: true,
      data: data
    }],
    plotOptions: {
        areaspline: {
            color: '#32CD32',
            fillColor: {
                linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                stops: [
                    [0, '#32CD32'],
                    [1, '#32CD3200']
                ]
            },
            threshold: null,
            marker: {
                lineWidth: 1,
                lineColor: null,
                fillColor: 'white'
            }
        }
    },

  };
  return (
    <div>
          <HighchartsReact
                  highcharts={Highcharts}
                  options={options}
                />
    </div>
  )
}

export default chart
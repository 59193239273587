import React, { useState } from "react";
import "../../Assets/Css/Auth.css";
import Logo from "../../Assets/Images/Logo.svg";
import LoginRight from "../../Assets/Images/LoginOtp.png";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { verifyotp, forgot } from "../../services/register";
import Loader from "../../common/loader";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { successToast, errorToast } from "../../common/toaster";
import { successMsgHandler } from "../../common/successMsgHandler";
import { errorMsgHandler } from "../../common/errorMsgHandler";
// import { apiHeader } from "../../helpers/apis_helper";

const Otp = ({ t }) => {
  const [loader, setLoader] = useState(false);
  let state = window.history.state.usr;
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  // const [showsend, setShowsend] = useState(true);
  // const [timer, setTimer] = useState(300);
  // useEffect(() => {
  //   if (timer === 0) {
  //     setShowsend(false);
  //   } else {
  //     setTimeout(() => {
  //       setTimer(timer - 1);
  //     }, 1000);
  //   }
  // }, [timer]);
  // useEffect(() => {
  //   const countdown = setInterval(() => {
  //     setTimer((prevTimer) => prevTimer - 1);
  //   }, 1000);

  //   // Cleanup function to clear the interval when the component is unmounted
  //   return () => clearInterval(countdown,setShowsend(false));
  // }, []);
  // const formatTime = (seconds) => {
  //   const minutes = Math.floor(seconds / 60);
  //   const remainingSeconds = seconds % 60;
  //   return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds} ${
  //     minutes < 1 ? "secounds" : "minutes"
  //   }`;
  // };
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: state,
      otp: "",
    },
    validationSchema: Yup.object({
      otp: Yup.string()
        .min(5, t("Invalid OTP"))
        .max(6, t("Invalid OTP"))
        .required(t("Please check the mail and enter the OTP.")),
    }),
    onSubmit: async (values) => {
      try {
        setLoader(true);
        verifyotp(values)
          .then((res) => {
            toast.success(t(`${successMsgHandler(res.message)}`));
            localStorage.setItem("fintech-user", JSON.stringify(res?.data));
            // apiHeader();
            navigate("/user-kyc");
            setLoader(false);
          })
          .catch((err) => {
            console.log(err, "err");
            toast.error(t(`${errorMsgHandler(err)}`));
            setLoader(false);
          });
      } catch (err) {
        console.log(err, "err");
        toast.error(t(`${errorMsgHandler(err)}`));
        setLoader(false);
      }
    },
  });

  const resendOtp = () => {
    setLoader(true);
    forgot({ email: state })
      .then((res) => {
        successToast("OTP resend successfully!");
        setLoader(false);
      })
      .catch((err) => {
        errorToast(err?.response?.data?.error);
        setLoader(false);
      });
  };

  return (
    <>
      {loader ? <Loader /> : ""}
      <div className="container mt-5">
        <div className="row justify-content-center align-items-center h-100 border-layout">
          <div className="col-lg-6 AuthLeft bg-white px-5">
            <img src={Logo} alt="" width={280} />
            <div className="d-flex justify-content-center mb-5 fs-2">
              <strong>OTP Verification</strong>
            </div>
            <span className="fs-6">
              Enter the OTP sent on your Email <strong>{state}</strong>
            </span>
            <div className="my-3 otpInput">
              <OtpInput
                value={otp}
                onChange={(enterOtp) => {
                  setOtp(enterOtp);
                  validation.setFieldValue("otp", enterOtp);
                  if (enterOtp.length === 6) {
                    validation.handleSubmit();
                  }
                }}
                numInputs={6}
                renderInput={(props) => <input {...props} />}
              />
            </div>
            <button
              type="submit"
              onClick={() => validation.handleSubmit()}
              className="w-100 mb-3 common-btn fw-bold"
            >
              Verify OTP
            </button>
            <div className="d-flex">
              <span className="fs-6">
                Don’t receive the OTP?{" "}
                <strong
                  className="themeText cursor-pointer"
                  onClick={() => resendOtp()}
                >
                  Resend OTP
                </strong>
              </span>
            </div>
          </div>
          <div className="col-lg-6 AuthRight p-5 d-flex justify-content-center align-items-center">
            <img src={LoginRight} alt="Login" className="img-fluid" />
          </div>
        </div>
      </div>
    </>
  );
};

export default withTranslation()(Otp);

Otp.propTypes = {
  t: PropTypes.any,
};

import React from "react";
import "../../Assets/Css/Auth.css";
import Modal from "react-bootstrap/Modal";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import "react-phone-input-2/lib/style.css";

const AddCardFrame = ({ show, handleClose, url, t }) => {
  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="xl"
      className="add-card-frame-modal"
      show={show}
      onHide={handleClose}
    >
      <Modal.Header closeButton className="montserrat-bold">
        <h5>{t("Add Card")}</h5>
      </Modal.Header>
      <Modal.Body>
        <iframe
          title="add card i frame"
          src={url}
          width="100%"
          height="100%"
        ></iframe>
      </Modal.Body>
    </Modal>
  );
};

export default withTranslation()(AddCardFrame);

AddCardFrame.propTypes = {
  t: PropTypes.any,
};

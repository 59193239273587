import React from "react";
import Logo from "../../Assets/Images/Logo-White.png";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

const Footer = ({ t }) => {
  const navigate = useNavigate();
  return (
    <div className="home_footer_page px-5">
      <div className="footer_link_content m-auto py-5">
        <div className="row">
          <div className="col-xl-4 col-sm-12">
            <img src={Logo} alt="header-logo" className="img-fluid" />
          </div>
          <div className="col-xl-4 col-sm-12 d-flex justify-content-center">
            <ul>
              <h5>{t("Company Terms")}</h5>
              <li
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/about-us")}
              >
                {t("About")}
              </li>
              <li
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/terms-conditions")}
              >
                {t("Terms & conditions")}
              </li>
              <li
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/privacy-policy")}
              >
                {t("Privacy Policy")}
              </li>
            </ul>
          </div>
          <div className="col-xl-4 col-sm-12 d-flex justify-content-center">
            <ul>
              <h5>{t("Contact Us")}</h5>
              <li>2715 Ash Loreum impulsum whatever</li>
              <li>314-568-5985 </li>
              <li> lureumimpulsum@gmail.com</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="pb-2">
        <hr style={{ opacity: "1" }} />
        <p>{t("Copyright © 2024. Designed & Developed by Inlexso.")}</p>
      </div>
    </div>
  );
};

export default withTranslation()(Footer);

Footer.propTypes = {
  t: PropTypes.any,
};

import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context.js";
import "./programming.css";
import ProgramDetailModal from "./ProgramDetailModal.js";
import {
  getInvestedSavingServices,
  savingServiceDetail,
  getPlanTerminationDetails,
  postTerminatePlan,
} from "../../services/cardServices.js";
import Pagination from "../../common/pagination.js";
import PlanTerminationDetailModal from "./PlanTerminationDetailModal.js";
import { errorToast, successToast } from "../../common/toaster.js";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

const Transfer = ({ t }) => {
  const naviagte = useNavigate();
  const { setPageContLoader } = useContext(UserContext);
  const language = localStorage.getItem("i18nextLng");
  const [detailData, setDetailData] = useState();
  const [planTerminationData, setPlanTerminationData] = useState();
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [showTerminateModal, setShowTerminateModal] = useState(false);
  const [pageData, setPageData] = useState();
  const [page, setPage] = useState({
    current: 1,
    totalItems: 0,
    pageSize: 10,
  });

  const fetch = (pageNumber = 1, pageSize = 10) => {
    setPageContLoader(true);
    getInvestedSavingServices(pageNumber, pageSize)
      .then((res) => {
        setPageContLoader(false);
        setData(res?.data?.items);
        setPageData(res?.data);
        setPage({ ...page, totalItems: res?.data?.totalCount });
      })
      .catch((err) => {
        setPageContLoader(false);
        console.log(err, "error of getAll products....");
      });
  };

  useEffect(() => {
    fetch();
  }, []);

  function convertMonthsToYears(months) {
    let years = Math.floor(months / 12);
    let remainingMonths = months % 12;
    if (years === 0) {
      return `${remainingMonths} ${t("months")}`;
    } else if (years === 1 && remainingMonths === 0) {
      return `1 ${t("year")}`;
    } else if (years === 1) {
      return `1 year ${remainingMonths} ${t("months")}`;
    } else if (remainingMonths === 0) {
      return `${years} ${t("years")}`;
    } else {
      return `${years} ${t("years")} ${remainingMonths} ${t("months")}`;
    }
  }

  const handleShowView = (item) => {
    savingServiceDetail(item?._id)
      .then((res) => {
        setDetailData(res?.data);
        setShow(true);
      })
      .catch((err) => {
        console.log(err, "error of getAll products....");
      });
  };

  const handleTerminatePlan = (item) => {
    getPlanTerminationDetails(item?._id)
      .then((res) => {
        setPlanTerminationData({ ...res?.data, serviceId: item?._id });
        setShowTerminateModal(true);
      })
      .catch((err) => {
        console.log(err, "error of getAll products....");
        errorToast(err?.response?.data?.error);
      });
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleCloseTerminateModal = () => {
    setShowTerminateModal(false);
  };

  const terminatePlan = (balanceToPlus, serviceId) => {
    try {
      postTerminatePlan({
        balanceToPlus: balanceToPlus,
        serviceId: serviceId,
      })
        .then((res) => {
          fetch();
          successToast(t("Plan terminated successfully"));
          handleCloseTerminateModal();
        })
        .catch((err) => {
          errorToast(err?.response?.data?.error);
        });
    } catch (err) {
      console.log(err, "err");
      // errorToast(err?.response?.data?.error);
    }
  };

  return (
    <>
      <div className="recent-transaction-div">
        <div className="custom-table mb-4">
          <table className="w-100">
            <tbody>
              <tr>
                <th>{t("Name")}</th>
                <th>{t("Amount")}</th>
                <th>{t("Interest")}</th>
                <th>{t("Tenure")}</th>
                <th>{t("Locking Period")}</th>
                {/* <th>{t("Minimum Deposit")}</th>
                <th>{t("Maximum Deposit")}</th> */}
                <th>{t("Early Withdraw Panelty")}</th>
                <th>{t("Action")}</th>
              </tr>
              {data?.length > 0 ? (
                data?.map((item, i) => (
                  <tr key={i} className="mb-2">
                    <td className="montserrat-regular f-16">
                      {language === "sp"
                        ? item?.service?.nameSp
                        : item?.service?.name}
                    </td>
                    <td className="montserrat-regular f-16">${item?.amount}</td>
                    <td className="montserrat-regular f-16">
                      {item?.service?.interestRate}%
                    </td>
                    <td className="montserrat-regular f-16">
                      {convertMonthsToYears(item?.service?.tenure)}
                    </td>
                    <td className="montserrat-regular f-16">
                      {convertMonthsToYears(item?.service?.lockingPeriod)}
                    </td>
                    {/* <td className="montserrat-regular f-16">
                      ${item?.service?.minimumDepositAmount}
                    </td>
                    <td className="montserrat-regular f-16">
                      ${item?.service?.maximumDepositAmount}
                    </td> */}
                    <td className="montserrat-regular f-16">
                      {item?.service?.earlyWithdrawalPenalty}%
                    </td>
                    <td>
                      <div className="d-flex justify-content-between">
                        <button
                          className="received-button mx-1"
                          // onClick={() => handleShowView(item)}
                          onClick={() =>
                            naviagte("/purchased-services/service-detail", {
                              state: {
                                id: item?._id,
                              },
                            })
                          }
                        >
                          {t("View")}
                        </button>
                        {item?.status == "WITHDRAW" ? (
                          <button className="btn btn-danger">
                            {t("Terminated")}
                          </button>
                        ) : item?.status == "COMPLETED" ? (
                          <button className="btn btn-success">
                            {t("Completed")}
                          </button>
                        ) : (
                          <button
                            className="received-button"
                            onClick={() => handleTerminatePlan(item)}
                          >
                            {t("Terminate")}
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7">{t("No Data Available")}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {pageData?.totalCount > 10 && (
          <Pagination setPage={setPage} page={page} data={pageData} />
        )}
      </div>
      <ProgramDetailModal
        show={show}
        setShow={setShow}
        handleClose={handleClose}
        data={detailData}
      />
      <PlanTerminationDetailModal
        show={showTerminateModal}
        setShow={setShowTerminateModal}
        handleClose={handleCloseTerminateModal}
        data={planTerminationData}
        terminatePlan={terminatePlan}
      />
    </>
  );
};

export default withTranslation()(Transfer);

Transfer.propTypes = {
  t: PropTypes.any,
};

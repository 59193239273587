/* eslint-disable */
import React, { useState, useEffect, useRef, useContext } from "react";
import { UserContext } from "../../context.js";
import "./Transactions.css";
import moment from "moment";
import { getTransactions } from "../../services/cardServices.js";
import Pagination from "../../common/pagination.js";
import SearchFilter from "../../Components/Common/SearchFilter.js";
import ExportCSV from "../../Components/Common/ExportCSV.js";
import html2canvas from "html2canvas";
import ReactDOM from "react-dom";
import { jsPDF } from "jspdf";
import Logo from "../../Assets/Images/Logo.svg";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Badge } from "reactstrap";
// import DateFilter from "../../Components/Common/DateFilter.js";

const Transactions = ({ t }) => {
  const { setPageContLoader } = useContext(UserContext);
  const receiptRef = useRef();
  const [data, setData] = useState([]);
  const [dateFilter, setDateFilter] = useState([]);
  const [pageData, setPageData] = useState();
  const [search, setSearch] = useState();
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [page, setPage] = useState({
    current: 1,
    totalItems: 0,
    pageSize: 10,
  });

  const fetch = (
    pageNumber = 1,
    pageSize = 10,
    search = "",
    dateFilter = [],
    exportRequest = "false"
  ) => {
    const [fromDate, toDate] = dateFilter || [];
    setPageContLoader(true);
    getTransactions(
      pageNumber,
      pageSize,
      search,
      fromDate || "",
      toDate || "",
      exportRequest
    )
      .then((res) => {
        if (exportRequest === "true") {
          setPageContLoader(false);
          return;
        }
        setData(
          res.data.items.map((val, index) => {
            return { ...val, serial: index + 1 };
          })
        );
        setPageContLoader(false);
        setPageData(res?.data);
        setPage({
          ...page,
          totalItems: res.data.totalItems,
          current: pageNumber,
        });
      })
      .catch((err) => {
        console.log(err, "error of getAll products....");
        setPageContLoader(false);
      });
  };

  const handleSearchClick = (e) => {
    const value = e.target.value;
    setSearch(value);
    setPage({
      ...page,
      current: 1,
    });
    clearTimeout(typingTimeout);
    const timeoutId = setTimeout(() => {
      fetch(1, page.pageSize, e.target.value, dateFilter);
    }, 500);

    setTypingTimeout(timeoutId);
  };

  const handleExportCsv = () => {
    fetch("1", "5000", search, dateFilter, "true");
  };

  useEffect(() => {
    fetch(page.current, page.pageSize, search, dateFilter);
  }, [page.current]);

  const downloadReceipt = async (item) => {
    // Create a temporary div to render the receipt off-screen
    const tempDiv = document.createElement("div");
    document.body.appendChild(tempDiv);

    const receiptComponent = (
      <div
        ref={receiptRef}
        style={{
          fontFamily: "Montserrat",
        }}
      >
        <div style={{ padding: "200px" }}>
          <div
            className="mb-3"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div>
              <img src={Logo} alt="" maxWidth={280} className="mx-5 " />
            </div>
            <h1>{t("Invoice")}</h1>
          </div>
          <div className="text-end mb-3">
            <p>
              {t("Transaction ID")}: ${item?._id}
            </p>
            <p>{moment(item.createdAt).format("lll")}</p>
          </div>
          <div>
            <hr />
            <div className="d-flex justify-content-between">
              <p>
                <strong>{t("Amount")}</strong>
              </p>
              <p>$ {item?.amount.toFixed(2)}</p>
            </div>
            <hr />
            <div className="d-flex justify-content-between">
              <strong>{t("Transaction Type")}:</strong>
              <p>{item?.transactionType?.replace("_", " ")}</p>
            </div>
            <hr />
            <div className="d-flex justify-content-between">
              <strong>{t("Payment Medium")}:</strong>
              <p>{item?.paymentMedium?.replace("_", " ")}</p>
            </div>
            <hr />
            <div className="d-flex justify-content-between">
              <strong>{t("Status")}:</strong>
              <p>
                {item?.status === "PENDING" ? t("COMPLETED") : item?.status}
              </p>
            </div>
            <hr />
          </div>
        </div>
      </div>
    );

    // Render the receipt component into the temporary div
    ReactDOM.render(receiptComponent, tempDiv);

    const canvas = await html2canvas(tempDiv);
    const dataUrl = canvas.toDataURL("image/png");

    const pdf = new jsPDF();
    const imgProperties = pdf.getImageProperties(dataUrl);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

    pdf.addImage(dataUrl, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save(`invoice_${item._id}.pdf`);

    // Clean up the temporary div
    ReactDOM.unmountComponentAtNode(tempDiv);
    document.body.removeChild(tempDiv);
  };

  const dateFilterChange = (e, picker) => {
    const filter = [
      picker.startDate.format("YYYY-MM-DD"),
      picker.endDate.format("YYYY-MM-DD"),
    ];
    setDateFilter(filter);
    fetch(1, page.pageSize, search, filter);
  };

  // const inputRef = useRef(null);

  return (
    <>
      <div className="recent-transaction-div">
        <div className="mb-2 text-end d-flex justify-lg-content-end px-3">
          <SearchFilter handleSearchClick={handleSearchClick} />
          {/* <DateFilter
            dateFilterChange={dateFilterChange}
            dateFilter={dateFilter}
            setDateFilter={setDateFilter}
            fetch={fetch}
            page={page}
            inputRef={inputRef}
            search={search}
          /> */}
          <ExportCSV handleExportCsv={handleExportCsv} />
        </div>
        <div className="custom-table mb-4">
          <table className="w-100">
            <tbody>
              <tr>
                <th>{t("Sr. No.")}</th>
                <th>{t("Transaction Id")}</th>
                <th>{t("Amount")}</th>
                <th>{t("Type")}</th>
                <th>{t("Medium")}</th>
                <th>{t("Status")}</th>
                <th>{t("Date")}</th>
                <th>{t("Action")}</th>
              </tr>
              {data?.length > 0 ? (
                data?.map((item, i) => (
                  <tr key={i} className="mb-2">
                    <td className="montserrat-regular f-16">
                      {item?.serial + (page.current - 1) * page.pageSize}
                    </td>
                    <td className="montserrat-regular f-16">{item?._id}</td>

                    <td className="montserrat-regular f-16">
                      $ {item?.amount?.toFixed(2)}
                    </td>

                    <td className={`montserrat-regular f-16 text-success`}>
                      {item?.transactionType == "Sell_Service"
                        ? "Terminated"
                        : item?.transactionType?.replace("_", " ")}
                    </td>
                    <td className="montserrat-regular f-16">
                      {item?.paymentMedium?.replace("_", " ")}
                    </td>
                    <td className="montserrat-regular f-16">
                      <Badge
                        color={
                          item?.status == "PENDING"
                            ? "success"
                            : item?.status == "FAILED"
                            ? "danger"
                            : "info"
                        }
                        className="me-1 p-2"
                      >
                        {item?.status === "PENDING"
                          ? t("COMPLETED")
                          : item?.status}
                      </Badge>
                    </td>
                    <td className="montserrat-regular f-16">
                      {moment(item?.createdAt).format("ll")}
                    </td>
                    <td>
                      <button
                        className="received-button"
                        onClick={() => downloadReceipt(item)}
                      >
                        {t("Download Receipt")}
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8">{t("No Data Available")}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {pageData?.totalItems > 10 && (
          <Pagination setPage={setPage} page={page} data={pageData} />
        )}
      </div>
    </>
  );
};

export default withTranslation()(Transactions);

Transactions.propTypes = {
  t: PropTypes.any,
};

import React, { useState, useContext } from "react";
import Sidebar from "./Sidebar";
import { Link, Outlet, useLocation } from "react-router-dom";
import LogoWhite from "../../Assets/Images/Logo-White.png";
import Logo_icon from "../../Assets/Images/logo-icon.png";
import "./Layout.css";
import { UserContext } from "../../context";
import { HiOutlineBars3CenterLeft } from "react-icons/hi2";
import Offcanvas from "react-bootstrap/Offcanvas";
import Header from "../Header/Header";
import Pagetitle from "../../common/title/pagetitle";
import PageLoader from "../../common/pageLoader";

const Layout = () => {
  const { pageContLoader } = useContext(UserContext);
  const location = useLocation();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const pathname = location?.pathname;
  const lastSegment = pathname.split("/").pop();

  return (
    <>
      <div className="mobile-header  d-md-none d-flex align-items-center justify-content-between p-3">
        <div>
          <Link className="mobile-logo" to="/">
            <img src={Logo_icon} alt="" className="img-fluid" />
          </Link>
        </div>
        <div className="d-flex align-items-center">
          <div className="d-flex justify-content-end  fs-4 align-items-center">
            <div className="demo">
              <Header title={lastSegment} />
            </div>

            <span onClick={handleShow}>
              <HiOutlineBars3CenterLeft className="f-28 ms-3" />
            </span>
          </div>
        </div>
      </div>

      <div className="d-flex layout-block">
        <Offcanvas show={show} onHide={handleClose}>
          <Offcanvas.Header closeButton>
            <Link to="/">
              <img src={LogoWhite} alt="" width={350} />
            </Link>
          </Offcanvas.Header>
          <Offcanvas.Body className="p-3">
            <Sidebar handleClose={handleClose} />
          </Offcanvas.Body>
        </Offcanvas>
        <div className="layout-sidebar d-lg-block d-none position-fixed">
          <Sidebar />
        </div>
        <div className="layout-right w-100 ">
          <div className="d-none d-md-block">
            <Header title={lastSegment} />
          </div>

          <div className="content-area">
            <Pagetitle />
            <PageLoader hidden={!pageContLoader} />
            <div hidden={pageContLoader}>
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;

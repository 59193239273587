import { Outlet, Navigate } from "react-router-dom";
import { useAuth, useVerified } from "../useAuth";

function KycProtectRoutes() {
  const token = useAuth();
  const kyc = useVerified();
  return token && !kyc ? <Outlet /> : <Navigate to="/" />;
}

export default KycProtectRoutes;

import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

const ExportCSV = ({ handleExportCsv, t }) => {
  return (
    <div className="export-csv">
      <button className="common-btn2 py-2 px-3 ms-2" onClick={handleExportCsv}>
        {t("Export CSV")}
      </button>
    </div>
  );
};

export default withTranslation()(ExportCSV);

ExportCSV.propTypes = {
  t: PropTypes.any,
};

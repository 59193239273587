import { useState } from "react";
import "../../Assets/Css/Auth.css";
import Logo from "../../Assets/Images/Logo.svg";
import Form from "react-bootstrap/Form";
import LoginRight from "../../Assets/Images/Login.png";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Loader from "../../common/loader";
import { resetPassword } from "../../services/register";
import { useFormik } from "formik";
import * as Yup from "yup";
import Backimg from "../../Assets/Images/backbutton.png";
import { successToast, errorToast } from "../../common/toaster.js";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { successMsgHandler } from "../../common/successMsgHandler.js";
import { errorMsgHandler } from "../../common/errorMsgHandler.js";

const ResetPassword = ({ t }) => {
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().required(t("Required")),
      confirmPassword: Yup.string()
        .required(t("Required"))
        .oneOf(
          [Yup.ref("password"), null],
          t("Must match Password field value")
        ),
    }),
    onSubmit: async (values) => {
      setLoader(true);
      resetPassword(values.password, localStorage.getItem("token-info"))
        .then((res) => {
          successToast(t(`${successMsgHandler(res?.message)}`));
          setLoader(false);
          navigate("/login");
        })
        .catch((err) => {
          errorToast(t(`${errorMsgHandler(err)}`));
          setLoader(false);
        });
    },
  });

  return (
    <div className="container mt-5">
      {loader ? <Loader /> : ""}
      <div>
        <div className="row   h-100 border-layout">
          <div className="col-lg-6 AuthLeft bg-white">
            <img
              src={Logo}
              style={{ cursor: "pointer", maxWidth: "280px" }}
              onClick={() => navigate("/")}
              alt=""
              className="mx-5 py-5"
            />
            <div className="px-5 ">
              <Link to="/">
                <img
                  alt="background"
                  src={Backimg}
                  style={{ width: "89px", height: "32px", cursor: "pointer" }}
                />
              </Link>
            </div>
            <div className="d-flex justify-content-center mb-5 fs-2 align-items-center">
              <strong className="montserrat-bold">{t("Reset Password")}</strong>
            </div>
            <Form onSubmit={validation.handleSubmit} className="px-5">
              <Form.Group controlId="validationCustom01" className="mb-3">
                <Form.Label className="montserrat-bold">
                  {t("Enter New Password")}
                </Form.Label>
                <Form.Control
                  name="password"
                  value={validation.values.password}
                  onChange={validation.handleChange}
                  type="password"
                  placeholder={t("New Password")}
                />
                {validation.touched.password && validation.errors.password ? (
                  <div style={{ color: "red" }}>
                    {validation.errors.password}
                  </div>
                ) : null}
              </Form.Group>
              <Form.Group controlId="validationCustom01">
                <Form.Label className="montserrat-bold">
                  {t("Confirm New Password")}
                </Form.Label>
                <Form.Control
                  name="confirmPassword"
                  value={validation.values.confirmPassword}
                  onChange={validation.handleChange}
                  type="password"
                  placeholder={t("Confirm Password")}
                />
                {validation.touched.confirmPassword &&
                validation.errors.confirmPassword ? (
                  <div style={{ color: "red" }}>
                    {validation.errors.confirmPassword}
                  </div>
                ) : null}
              </Form.Group>
              <button type="submit" className="w-100 my-5 common-btn fw-bold">
                {t("Submit")}
              </button>
            </Form>
          </div>
          <div className="col-lg-6 AuthRight p-5 d-flex justify-content-center align-items-center">
            <img
              src={LoginRight}
              style={{ width: "100%" }}
              alt="Login"
              className=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(ResetPassword);

ResetPassword.propTypes = {
  t: PropTypes.any,
};

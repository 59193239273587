import React from "react";
import { AiOutlineEye } from "react-icons/ai";
import { AiOutlineEyeInvisible } from "react-icons/ai";

const PasswordTypeComp = ({ passwordType, setPasswordType }) => {
  return (
    <span className="view_password">
      {passwordType === "password" ? (
        <AiOutlineEye
          onClick={() => setPasswordType(!passwordType)}
          className="view_password"
        />
      ) : (
        <AiOutlineEyeInvisible
          onClick={() => setPasswordType("password")}
          className="view_password"
        />
      )}
    </span>
  );
};

export default PasswordTypeComp;

/* eslint-disable */
import React, { useEffect, useState, useContext } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { Badge } from "antd";
import { GoBell } from "react-icons/go";
import { withTranslation } from "react-i18next";
import { UserContext } from "../../context";
import moment from "moment";
import { IoNotificationsCircle } from "react-icons/io5";
import { updateNotificationStatus } from "../../services/user";

const NotificationDropdown = ({ t }) => {
  const [menu, setMenu] = useState(false);
  const { notifications, getAllNotifications, notificationCount } =
    useContext(UserContext);

  const toggle = () => {
    setMenu(!menu);
    menu === true && changeNotificationStatus();
  };

  const changeNotificationStatus = () => {
    try {
      updateNotificationStatus({ view: true })
        .then((res) => {
          getAllNotifications();
        })
        .catch((err) => {
          console.log(err, "err");
        });
    } catch (err) {}
  };

  useEffect(() => {
    getAllNotifications();
  }, []);

  return (
    <>
      <Dropdown
        onClick={() => getAllNotifications()}
        isOpen={menu}
        toggle={toggle}
        className="d-inline-block"
      >
        <DropdownToggle className="btn header-item" tag="button">
          <span>
            <Badge count={notificationCount}>
              <GoBell className="fs-4 mb-1" />
            </Badge>
          </span>
        </DropdownToggle>
        <DropdownMenu
          className=" dropdown-menu-end"
          style={{
            maxHeight: "500px",
            overflowY: "auto",
            borderRadius: "20px",
            maxWidth: "520px",
          }}
        >
          <h5 className="text-center mt-2">{t("Notification")}</h5>
          {notifications?.length > 0 ? (
            notifications?.map((item, index) => (
              <DropdownItem key={index} className={`notify-item`}>
                <div key={item?.id}>
                  <div
                    style={{
                      background: item?.view !== true && "#77c4ff2b",
                      borderRadius: "8px",
                      padding: "0px",
                    }}
                  >
                    <div className="d-flex">
                      <IoNotificationsCircle color="#317e9e" size={30} />
                      <p
                        className="mb-0 ms-2"
                        style={{
                          width: "400px",
                          wordWrap: "break-word",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        {item?.message ? item?.message : item?.notifications}
                        <br />
                        <h6
                          style={{
                            fontSize: "14px",
                            color: "#317e9e",
                            fontWeight: "600",
                          }}
                        >
                          {moment(item?.createdAt).format("lll")}
                        </h6>
                      </p>
                    </div>
                  </div>
                  {index !== notifications.length - 1}
                </div>
              </DropdownItem>
            ))
          ) : (
            <DropdownItem
              className={`notify-item p-4`}
              style={{ width: "400px" }}
            >
              {t("No Notification")}
            </DropdownItem>
          )}
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default withTranslation()(NotificationDropdown);

import React from "react";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import { withTranslation } from "react-i18next";
import Form from "react-bootstrap/Form";

const PurchaseService = ({
  show,
  loader,
  setAmount,
  setAmount1,
  amount,
  handleClose,
  serviceData,
  handleSubmit,
  handleAmountChange,
  calculatedData,
  check,
  setCheck,
  t,
}) => {
  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={handleClose}
    >
      <Modal.Header closeButton className="montserrat-bold">
        <h5>{t("Purchase Program Saving Service")}</h5>
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId="validationCustom01" className="mb-3">
          <Form.Label className="montserrat-bold">
            {t("Enter Amount")}
          </Form.Label>
          <Form.Control
            onChange={(e) => {
              handleAmountChange(serviceData?._id, e);
            }}
            required
            autoComplete="off"
            value={amount}
            // min={+serviceData?.minimumDepositAmount}
            // max={+serviceData?.maximumDepositAmount}
            type="number"
            placeholder=""
          />
        </Form.Group>
        <div>
          <div className="row">
            <div className="col-6">
              <h6>{t("Minimum Amount")} </h6>
            </div>
            <div className="col-6">
              <p>${serviceData?.minimumDepositAmount}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <h6>{t("Maximum Amount")}</h6>
            </div>
            <div className="col-6">
              <p>${serviceData?.maximumDepositAmount}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <h6>
                {t("Interest")} ({serviceData?.interestRate}%)
              </h6>
            </div>
            <div className="col-6">
              <p>${calculatedData?.interestAmount?.toFixed(2)}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <h6>{t("Early Withdraw Panelty")} </h6>
            </div>
            <div className="col-6">
              <p>{calculatedData?.earlyWithdrawalPenalty}%</p>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <h6>{t("Maturity Amount")}</h6>
            </div>
            <div className="col-6">
              <p>${calculatedData?.maturityAmount?.toFixed(2)}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <h6>{t("Maturity Date")}</h6>
            </div>
            <div className="col-6">
              <p>
                {calculatedData?.maturityDate
                  ? moment(calculatedData?.maturityDate).format("ll")
                  : "-"}
              </p>
            </div>
          </div>
        </div>
        <div className="d-flex gap-2 my-4">
          <input
            onChange={(e) => setCheck(e.target.checked)}
            style={{ cursor: "pointer" }}
            type="checkbox"
            id="vehicle1"
            name="vehicle1"
            value="Bike"
          />
          <span>
            {t("I agree to the")}{" "}
            <a href="/service-t&c" target="_blank" className="text-primary">
              {t("Terms & Conditions")}
            </a>
          </span>
        </div>
        <div className="d-flex gap-2">
          <button
            disabled={amount < 1 || !check || loader}
            className="w-100 mb-3 common-btn fw-bold"
            onClick={() => {
              handleSubmit(amount, "wallet");
            }}
          >
            {t("Pay with Wallet")}
          </button>
          <button
            disabled={amount < 1 || !check}
            className="w-100 mb-3 common-btn fw-bold"
            onClick={() => {
              handleSubmit(amount, "card");
            }}
          >
            {t("Pay with Card")}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default withTranslation()(PurchaseService);

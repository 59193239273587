import React, { useState, useContext } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { withTranslation } from "react-i18next";
import DummyImage from "../../Assets/Images/dummy.jpg";
import { API_IMAGE_URL } from "../../helpers/apis_helper";
import { UserContext } from "../../context";
import { confirmSwal } from "../../common/toaster";
import { useNavigate } from "react-router-dom";

const ProfileDropdown = ({ t }) => {
  const navigate = useNavigate();
  const [menu, setMenu] = useState(false);
  const { userData } = useContext(UserContext);
  const toggle = () => {
    setMenu(!menu);
  };

  const logoutFunc = () => {
    localStorage.removeItem("fintech-user");
    navigate("/home");
  };

  const handleLogout = () => {
    confirmSwal(
      `${t("Are you sure?")}`,
      `${t("You want to Log Out?")}`,
      `${t("Yes, Logout it!")}`,
      logoutFunc,
      `${t("Cancel")}`
    );
  };

  return (
    <>
      <Dropdown
        isOpen={menu}
        toggle={toggle}
        className="d-inline-block profile-dropdown"
      >
        <DropdownToggle className="btn header-item" tag="button">
          <div className="rounded">
            <img
              style={{ borderRadius: "50%", height: "50px", width: "50px" }}
              src={
                userData?.image ? API_IMAGE_URL + userData?.image : DummyImage
              }
              alt="profile"
              width={50}
              height={50}
              className="img-fluid"
            />
          </div>
        </DropdownToggle>
        <DropdownMenu className="language-switch dropdown-menu-end p-2">
          <DropdownItem
            style={{ color: "#317d9d", fontWeight: "500" }}
            className={`notify-item`}
            onClick={() => navigate("profile-settings")}
          >
            {t("Profile")}
          </DropdownItem>
          <hr className="m-0" />
          <DropdownItem
            onClick={handleLogout}
            style={{ color: "#317d9d", fontWeight: "500" }}
            className={`notify-item`}
          >
            {t("Logout")}
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default withTranslation()(ProfileDropdown);

import { post, put } from "../helpers/apis_helper";

export const register = (data) => {
  return post("/client/register", data);
};

export const verifyotp = (data) => {
  return post("/client/verifyotp", data);
};

export const verifForgotOtp = (data) => {
  return put("/client/verifyOtp", data);
};

export const login = (data) => {
  return post("/client/login", data);
};

export const forgot = (data) => {
  return post("/client/forgotPassword", data);
};

export const resetPassword = (new_password, token) => {
  return post(
    "/client/resetPassword",
    { new_password },
    {
      headers: { Authorization: token },
    }
  );
};

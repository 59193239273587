import React from "react";
import "./loader.css";

const CommonLoader = () => {
  return (
    <div className="content-loader-container">
      <div className="donut"> </div>
    </div>
  );
};

export default CommonLoader;

import React, { useEffect, useState } from "react";
import "../../Assets/Css/Auth.css";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";
import OtpInput from "react-otp-input";

const Otp = ({ email, show, handleClose, validation, resend, t }) => {
  const [timer, setTimer] = useState(300);

  useEffect(() => {
    if (timer > 0) {
      const countdown = setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
      return () => clearTimeout(countdown);
    }
  }, [timer]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds} ${
      minutes < 1 ? "seconds" : "minutes"
    }`;
  };

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={handleClose}
    >
      <Modal.Header closeButton className="montserrat-bold">
        <h5>{t("Verify OTP")}</h5>
      </Modal.Header>
      <Modal.Body>
        <div className="container my-4">
          <div className="justify-content-center align-items-center h-100">
            <div className="AuthLeft bg-white">
              <span className="fs-6">
                {t("Enter the OTP sent to your Email")} <strong>{email}</strong>
              </span>
              <div className="my-3 otpInput">
                <OtpInput
                  value={validation.values.otp}
                  onChange={(enterOtp) => {
                    validation.setFieldValue("otp", enterOtp);
                    if (enterOtp.length === 6) {
                      validation.handleSubmit();
                    }
                  }}
                  numInputs={6}
                  renderInput={(props) => <input {...props} />}
                />
              </div>
              <button
                onClick={() => validation.handleSubmit()}
                className="w-100 mb-3 common-btn fw-bold"
              >
                {t("Verify OTP")}
              </button>
              <div className="d-flex">
                <span className="fs-6">
                  {t("Didn’t receive the OTP?")}{" "}
                  <strong
                    className={`themeText cursor-pointer ${
                      timer > 0 ? "disabled" : ""
                    }`}
                    onClick={timer === 0 ? resend : null}
                    style={{
                      pointerEvents: timer > 0 ? "none" : "auto",
                      color: timer > 0 ? "grey" : "inherit",
                    }}
                  >
                    {t("Resend OTP")}
                  </strong>
                </span>
                {timer > 0 && (
                  <span className="ml-2">({formatTime(timer)})</span>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default withTranslation()(Otp);

Otp.propTypes = {
  t: PropTypes.any,
};

export const successMsgHandler = (responseMsg) => {
  return responseMsg === "Updated Successfully"
    ? "Updated Successfully"
    : responseMsg === "Password changed successfully"
    ? "Password changed successfully"
    : responseMsg === "OTP Sent Successfully"
    ? "OTP Sent Successfully"
    : responseMsg === "Verified Successfully!"
    ? "Verified Successfully!"
    : responseMsg === "Email sent successfully!!"
    ? "Reset Link"
    : responseMsg === "Password Reset Successfully"
    ? "Password Reset Successfully"
    : responseMsg;
};

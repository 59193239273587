import React from "react";
import Form from "react-bootstrap/Form";
import "./settings.css";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

const ChangePassword = ({ validation, t }) => {
  return (
    <>
      <div className="recent-transaction-div my-5">
        <div className="container ">
          <div className="h-100 border-layout p-5">
            <h1 className="text-uppercase montserrat-bold letter-2 f-22">
              {t("Change Password")}
            </h1>

            <Form onSubmit={validation.handleSubmit} className="row mt-5">
              <Form.Group
                controlId="validationCustom01"
                className="col-12 mb-3"
              >
                <Form.Label className="montserrat-bold">
                  {t("Old Password")}
                </Form.Label>
                <Form.Control
                  name="oldPassword"
                  value={validation.values.oldPassword}
                  onChange={validation.handleChange}
                  type="password"
                  placeholder=""
                />
                {validation.touched.oldPassword &&
                validation.errors.oldPassword ? (
                  <div style={{ color: "red" }}>
                    {validation.errors.oldPassword}
                  </div>
                ) : null}
              </Form.Group>
              <Form.Group
                controlId="validationCustom01"
                className="col-md-6 mb-3"
              >
                <Form.Label className="montserrat-bold">
                  {t("New Password")}
                </Form.Label>
                <Form.Control
                  name="newPassword"
                  value={validation.values.newPassword}
                  onChange={validation.handleChange}
                  type="password"
                  placeholder=""
                />
                {validation.touched.newPassword &&
                validation.errors.newPassword ? (
                  <div style={{ color: "red" }}>
                    {validation.errors.newPassword}
                  </div>
                ) : null}
              </Form.Group>
              <Form.Group
                controlId="validationCustom01"
                className="col-md-6 mb-3"
              >
                <Form.Label className="montserrat-bold">
                  {t("Confirm Password")}
                </Form.Label>
                <Form.Control
                  name="confirmPassword"
                  value={validation.values.confirmPassword}
                  onChange={validation.handleChange}
                  type="password"
                  placeholder=""
                />
                {validation.touched.confirmPassword &&
                validation.errors.confirmPassword ? (
                  <div style={{ color: "red" }}>
                    {validation.errors.confirmPassword}
                  </div>
                ) : null}
              </Form.Group>

              <div className="mt-4 text-center">
                <button type="submit" className="common-btn px-2 fw-bold">
                  {t("Update")}
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default withTranslation()(ChangePassword);

ChangePassword.propTypes = {
  t: PropTypes.any,
};

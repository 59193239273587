import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useFormik } from "formik";
import * as Yup from "yup";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

const AddBalanceModal = ({
  loader,
  show,
  handleClose,
  setShow1,
  setAmount,
  handleAddbalSubmit,
  handleRecharge,
  cardData,
  // handleAddBalCard,
  t,
}) => {
  const [newCard, setNewCard] = useState(false);
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      amount: "",
      name: "",
      cardNumber: "",
      securityCode: "",
      expiryDate: "",
    },
    validationSchema: Yup.object({
      amount: Yup.string().required(t("Required")),
      name: Yup.string().required(t("Required")),
      cardNumber: Yup.string().required(t("Required")),
      securityCode: Yup.string().required(t("Required")),
      expiryDate: Yup.string()
        .required(t("Required"))
        .matches(
          /^\d{4}-(0[1-9]|1[0-2])$/,
          t("Expiry date must be in the format YYYY-MM")
        ),
    }),
    onSubmit: async (values) => {
      let apiData = {
        purchase_units: [
          {
            amount: {
              currency_code: "USD",
              value: values?.amount,
            },
          },
        ],
        intent: "CAPTURE",
        payment_source: {
          card: {
            name: values?.name,
            number: values?.cardNumber,
            security_code: values?.securityCode,
            expiry: values?.expiryDate,
            billing_address: {
              country_code: "US",
            },
          },
        },
      };

      handleAddbalSubmit(apiData);
    },
  });
  const validation1 = useFormik({
    enableReinitialize: true,
    initialValues: {
      amount: "",
      document_number: "",
      comment: "",
      card_token: "",
    },
    validationSchema: Yup.object({
      amount: Yup.number()
        .required(t("Required"))
        .notOneOf([0], t("Amount must be greater than 0"))
        .min(0, t("Amount must be greater than 0")),
      document_number: Yup.string().required(t("Required")),
      comment: Yup.string().required(t("Required")),
    }),
    onSubmit: async (values, { resetForm }) => {
      // handleAddBalCard(values);
      // console.log(values, "valuess");
      handleRecharge(values, resetForm);
    },
  });
  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={handleClose}
    >
      <Modal.Header closeButton className="montserrat-bold">
        <h5>{t("Add balance to your wallet")}</h5>
      </Modal.Header>
      <Modal.Body>
        {!newCard && cardData?.length > 0 ? (
          <Form className={""} onSubmit={validation1.handleSubmit}>
            <Form.Group controlId="validationCustom02" className=" my-3">
              <Form.Label>{t("Enter Amount")}</Form.Label>
              <Form.Control
                name="amount"
                value={validation1.values.amount}
                onChange={validation1.handleChange}
                type="number"
                placeholder=""
              />
              {validation1.touched.amount && validation1.errors.amount ? (
                <div style={{ color: "red" }}>{validation1.errors.amount}</div>
              ) : null}
            </Form.Group>
            <Form.Group controlId="validationCustom02" className="mb-3 col-12">
              <Form.Label>{t("Card Type")}</Form.Label>
              <Form.Select
                name="document_number"
                // onChange={validation1?.handleChange}
                onChange={(e) => {
                  validation1.setFieldValue("document_number", e.target.value);
                  validation1.setFieldValue(
                    "card_token",
                    e.target.options[e.target.selectedIndex].getAttribute(
                      "token"
                    )
                  );
                }}
                value={validation1?.values.document}
                aria-label="Default select example"
              >
                <option value="">--{t("Select")}-</option>
                {cardData?.map((cardItem) =>
                  cardItem?.cards?.map((item, i) => (
                    <option
                      value={cardItem?.document}
                      token={item?.token}
                      key={i}
                    >
                      {item?.number}
                    </option>
                  ))
                )}
              </Form.Select>

              {validation1?.touched?.document_number &&
              validation1?.errors?.document_number ? (
                <div style={{ color: "red" }}>
                  {validation1?.errors?.document_number}
                </div>
              ) : null}
            </Form.Group>
            <Form.Group controlId="validationCustom02" className="my-3">
              <Form.Label>{t("Description")}</Form.Label>
              <Form.Control
                name="comment"
                value={validation1.values.comment}
                onChange={validation1.handleChange}
                type="text"
                as="textarea"
                rows={3}
                placeholder=""
              />
              {validation1.touched.comment && validation1.errors.comment ? (
                <div style={{ color: "red" }}>{validation1.errors.comment}</div>
              ) : null}
            </Form.Group>
            <div className="d-flex justify-content-between">
              {/* <button
                className="w-100 mb-3 common-btn fw-bold mx-1"
                type="button"
                onClick={() => setNewCard(true)}
              >
                {t("New Card")}
              </button> */}
              <button
                className="w-100 mb-3 common-btn fw-bold"
                type="submit"
                disabled={loader}
              >
                {loader ? (
                  <span className="typeWriter">
                    Loading<span> . . . . .</span>
                  </span>
                ) : (
                  t("Click to Proceed")
                )}
              </button>
            </div>
          </Form>
        ) : (
          <Form className={""} onSubmit={validation.handleSubmit}>
            <Form.Group controlId="validationCustom02" className=" my-3">
              <Form.Label>{t("Enter Amount")}</Form.Label>
              <Form.Control
                name="amount"
                value={validation.values.amount}
                onChange={validation.handleChange}
                type="number"
                placeholder=""
              />
              {validation.touched.amount && validation.errors.amount ? (
                <div style={{ color: "red" }}>{validation.errors.amount}</div>
              ) : null}
            </Form.Group>
            <div className="row my-3">
              <Form.Group controlId="validationCustom02" className=" col-6">
                <Form.Label>{t("Name")}</Form.Label>
                <Form.Control
                  name="name"
                  value={validation.values.name?.replace(/[^a-zA-Z]/g, "")}
                  onChange={validation.handleChange}
                  type="text"
                  placeholder=""
                />
                {validation.touched.name && validation.errors.name ? (
                  <div style={{ color: "red" }}>{validation.errors.name}</div>
                ) : null}
              </Form.Group>
              <Form.Group controlId="validationCustom02" className="col-6">
                <Form.Label>{t("Card Number")}</Form.Label>
                <Form.Control
                  value={validation.values.cardNumber?.replace(/[^0-9]/g, "")}
                  onChange={validation.handleChange}
                  name="cardNumber"
                  type="text"
                  minLength={8}
                  maxLength={20}
                  placeholder=""
                />
                {validation.touched.cardNumber &&
                validation.errors.cardNumber ? (
                  <div style={{ color: "red" }}>
                    {validation.errors.cardNumber}
                  </div>
                ) : null}
              </Form.Group>
            </div>
            <div className="row my-3">
              <Form.Group controlId="validationCustom01" className="col-6">
                <Form.Label>{t("Expiry Date")}</Form.Label>
                <Form.Control
                  name="expiryDate"
                  type="text"
                  onChange={validation.handleChange}
                  placeholder="YYYY-MM"
                />
                {validation.touched.expiryDate &&
                validation.errors.expiryDate ? (
                  <div style={{ color: "red" }}>
                    {validation.errors.expiryDate}
                  </div>
                ) : null}
              </Form.Group>

              <Form.Group controlId="validationCustom02" className="col-6">
                <Form.Label>{t("Security Code")}</Form.Label>
                <Form.Control
                  name="securityCode"
                  onChange={validation.handleChange}
                  type="text"
                  placeholder=""
                  value={validation.values.securityCode?.replace(/[^0-9]/g, "")}
                />
                {validation.touched.securityCode &&
                validation.errors.securityCode ? (
                  <div style={{ color: "red" }}>
                    {validation.errors.securityCode}
                  </div>
                ) : null}
              </Form.Group>
            </div>
            <div className="d-flex justify-content-between">
              {cardData?.length > 0 && (
                <button
                  className="w-100 mb-3 common-btn fw-bold mx-1"
                  type="button"
                  onClick={() => setNewCard(false)}
                >
                  {t("Select Card")}
                </button>
              )}
              <button
                className="w-100 mb-3 common-btn fw-bold mx-1"
                type="submit"
              >
                {t("Click to Proceed")}
              </button>
            </div>
          </Form>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default withTranslation()(AddBalanceModal);

AddBalanceModal.propTypes = {
  t: PropTypes.any,
};

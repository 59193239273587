import React, { useState } from "react";
import "../../Assets/Css/Auth.css";
import Logo from "../../Assets/Images/Logo.svg";
import Form from "react-bootstrap/Form";
import LoginRight from "../../Assets/Images/Signup.png";
import { Link } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { register } from "../../services/register";
import Loader from "../../common/loader";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import PasswordTypeComp from "../Common/PasswordTypeComp";

const Signup = ({ t }) => {
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const [passwordType, setPasswordType] = useState("password");

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      mobileNo: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required(t("Required")).email(t("Invalid email")),
      firstName: Yup.string()
        .required(t("Required"))
        .min(3, t("Must be more than 3 characters")),
      lastName: Yup.string().required(t("Required")),
      mobileNo: Yup.string().required(t("Required")),
      password: Yup.string().required(t("Required")),
    }),
    onSubmit: async (values) => {
      setLoader(true);
      register({
        firstName: values?.firstName,
        lastName: values?.lastName,
        mobileNo: values?.mobileNo.toString(),
        email: values?.email,
        password: values?.password,
        confirmPassword: values?.password,
      })
        .then((res) => {
          navigate("/otp-verification", {
            state: values?.email,
            password: values?.password,
          });
          setLoader(false);
          toast.success(t("OTP Sent Successfully"));
        })
        .catch((err) => {
          console.log(err?.response, "err");
          setLoader(false);
          toast.error(err?.response?.data?.error);
        });
    },
  });

  return (
    <>
      {loader ? <Loader /> : ""}
      <div className="container mt-5">
        <div className="row d-flex   h-100 border-layout">
          <div className="col-lg-6 AuthLeft bg-white py-5">
            <img
              src={Logo}
              alt=""
              style={{ cursor: "pointer", maxWidth: "280px" }}
              onClick={() => navigate("/")}
              className="mx-5"
            />

            <div className="d-flex justify-content-center mb-5 fs-2">
              <strong className="montserrat-bold">
                {t("Create an Account")}
              </strong>
            </div>
            <Form className="px-5" onSubmit={validation.handleSubmit}>
              <div className="row">
                <Form.Group controlId="validationCustom01" className="col-6">
                  <Form.Label className="montserrat-bold">
                    {t("First Name")}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t("First Name")}
                    value={validation?.values?.firstName?.replace(
                      /[^a-zA-Z]/g,
                      ""
                    )}
                    minLength={3}
                    maxLength={40}
                    onChange={validation.handleChange}
                    name="firstName"
                  />
                  {validation.touched.firstName &&
                  validation.errors.firstName ? (
                    <div style={{ color: "red" }}>
                      {validation.errors.firstName}
                    </div>
                  ) : null}
                </Form.Group>

                <Form.Group controlId="validationCustom02" className="col-6">
                  <Form.Label className="montserrat-bold">
                    {t("Last Name")}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={t("Last Name")}
                    value={validation?.values?.lastName?.replace(
                      /[^a-zA-Z]/g,
                      ""
                    )}
                    maxLength={30}
                    onChange={validation.handleChange}
                    name="lastName"
                  />
                  {validation.touched.lastName && validation.errors.lastName ? (
                    <div style={{ color: "red" }}>
                      {validation.errors.lastName}
                    </div>
                  ) : null}
                </Form.Group>
              </div>
              <Form.Group controlId="validationCustom02" className="mt-3">
                <Form.Label className="montserrat-bold">
                  {t("Email ID")}
                </Form.Label>
                <Form.Control
                  type="email"
                  placeholder={t("Email ID")}
                  value={validation.values.email}
                  onChange={validation.handleChange}
                  name="email"
                />
                {validation.touched.email && validation.errors.email ? (
                  <div style={{ color: "red" }}>{validation.errors.email}</div>
                ) : null}
              </Form.Group>
              <Form.Group controlId="validationCustom02" className="mt-3">
                <Form.Label className="montserrat-bold">
                  {t("Phone Number")}
                </Form.Label>
                <PhoneInput
                  inputProps={{
                    name: "phoneNumber",
                    required: true,
                    autoFocus: true,
                    autoComplete: "off",
                  }}
                  inputStyle={{
                    width: "100%",
                    height: "50px",
                    paddingLeft: "42px",
                    border: "1px solid #ced4da",
                    borderRadius: "5px",
                  }}
                  dropdownStyle={{
                    zIndex: 9999,
                  }}
                  country={"us"}
                  value={validation.values.mobileNo}
                  onChange={(phone) =>
                    validation.setFieldValue("mobileNo", phone)
                  }
                />
                {validation.touched.mobileNo && validation.errors.mobileNo ? (
                  <div style={{ color: "red" }}>
                    {validation.errors.mobileNo}
                  </div>
                ) : null}
              </Form.Group>
              <Form.Group
                controlId="validationCustom02"
                className="mt-3 position-relative"
              >
                <Form.Label className="montserrat-bold">
                  {t("Choose Password")}
                </Form.Label>
                <Form.Control
                  type={passwordType}
                  pattern="(?=.*[0-9])(?=.*[!@#$%^&*])\S{6,}"
                  title={t(
                    "Please enter a password with at least one digit and one special character, and a minimum length of 6 characters."
                  )}
                  placeholder={t("Password")}
                  value={validation.values.password}
                  onChange={validation.handleChange}
                  name="password"
                />
                <PasswordTypeComp
                  passwordType={passwordType}
                  setPasswordType={setPasswordType}
                />
                {validation.touched.password && validation.errors.password ? (
                  <div style={{ color: "red" }}>
                    {validation.errors.password}
                  </div>
                ) : null}
                <Form.Control.Feedback type="invalid">
                  {t(
                    "Please enter a password with at least one digit and one special character, and a minimum length of 6 characters."
                  )}
                </Form.Control.Feedback>
              </Form.Group>
              <button
                type="submit"
                disabled={loader}
                className="w-100 my-4 common-btn fw-bold"
              >
                {t("Sign Up")}
              </button>
              <div className="d-flex justify-content-center">
                <span className="fs-6 montserrat-bold">
                  {t("Already have an account?")}{" "}
                  <Link to={"/login"} className="themeText">
                    {t("Log In")}
                  </Link>
                </span>
              </div>
            </Form>
          </div>
          <div className="col-lg-6 AuthRight p-5 d-flex justify-content-center align-items-center">
            <img src={LoginRight} alt="Login" className="img-fluid" />
          </div>
        </div>
      </div>
    </>
  );
};

export default withTranslation()(Signup);

Signup.propTypes = {
  t: PropTypes.any,
};

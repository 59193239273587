import React, { useState, useContext, useEffect } from "react";
import { Nav } from "react-bootstrap";
import "./programming.css";
// import Graph from "../../Components/charts/graph.js";
import AddBalanceModal from "../Traditional-banking/AddBalanceModal.js";
import WithdrawBalModal from "./WithdrawBalModal.js";
import PaypalModal from "../Traditional-banking/PaypalModal.js";
import CardSlider from "../../Components/Programming/CardSlider.js";
import { UserContext } from "../../context";
import banner from "../../Assets/Images/banner.png";
import CommonLoader from "../../common/commonLoader.js";
import { IoIosRefresh } from "react-icons/io";
import Tooltip from "../../Components/Common/Tooltip.js";
import AddCardFrame from "../../Components/Programming/AddCardFrame.js";
import {
  getSavingServices,
  buySavingService,
  buySavingServiceWithCard,
  buySavingServiceWithExistingCard,
  saveCardFrameDetails,
  getCalculatedData,
  rechargeWallet,
  rechargeBalanceWallet,
  withdrawBalance,
  removeAddedCard,
} from "../../services/cardServices.js";
import PurchaseService from "./PurchaseService.js";
import Pagination from "../../common/pagination.js";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { successToast, errorToast } from "../../common/toaster.js";
import PayWithCard from "../../Components/Programming/PayWithCard.js";
import CardDetail2 from "../../Components/Programming/CardDetails2.js";

const Transfer = ({ t }) => {
  const language = localStorage.getItem("i18nextLng");
  const {
    cardLoader,
    cardData,
    userData,
    getUserData,
    getCardData,
    getAllNotifications,
  } = useContext(UserContext);
  const [activeTab, setActiveTab] = useState("deposit");
  const [check, setCheck] = useState(false);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [showCardPay, setShowCardPay] = useState(false);
  const [showAddBal, setShowAddBal] = useState(false);
  const [showWithdBal, setshowWithdBal] = useState(false);
  const [showCardDetail, setShowCardDetail] = useState(false);
  const [showAddCardFrame, setShowAddCardFrame] = useState(false);
  const [amount, setAmount] = useState("");
  const [amount1, setAmount1] = useState("");
  const [balAmount, setBalAmount] = useState("");
  const [withdAmount, setWithdAmount] = useState("");
  const [show1, setShow1] = useState(false);
  const [serviceData, setServiceData] = useState();
  const [calculatedData, setCalculatedData] = useState();
  const [frameUrl, setFrameUrl] = useState("");
  const [pageData, setPageData] = useState();
  const [page, setPage] = useState({
    current: 1,
    totalItems: 0,
    pageSize: 5,
  });

  const handleCloseCardDetail = () => {
    setShowCardDetail(false);
  };

  const handleCloseAddCardFrame = () => {
    setShowAddCardFrame(false);
    getCardData();
  };

  const handleTabSelect = (eventKey) => {
    setActiveTab(eventKey);
  };
  const handleClose1 = () => {
    setShow1(false);
    setBalAmount("");
  };
  const handleClose = () => {
    setShow(false);
    setAmount("");
    setCheck(false);
    setCalculatedData();
  };

  useEffect(() => {
    getUserData();
    getCardData();
    // eslint-disable-next-line
  }, []);

  const handleBalClose = () => {
    setShowAddBal(false);
  };
  const handleWithdClose = () => {
    setshowWithdBal(false);
  };
  const handleCloseShowCard = () => {
    setShowCardPay(false);
  };

  const handleCardPaySubmit = (apiData) => {
    try {
      setLoader(true);
      buySavingServiceWithCard({ ...apiData, serviceId: serviceData?._id })
        .then((res) => {
          successToast("Program-service purchased successfully!");
          setLoader(false);
          handleCloseShowCard();
          setAmount("");
          getUserData();
          fetch();
        })
        .catch((err) => {
          setLoader(false);
          console.log(err, "err");
          errorToast(err?.response?.data?.error?.message);
        });
    } catch (err) {
      setLoader(false);
      console.log(err, "errcatch");
      errorToast(err);
    }
  };

  // const handleCardDetailSubmit = (values) => {
  //   try {
  //     saveCardDetails(values)
  //       .then((res) => {
  //         successToast("Card details saved successfully!");
  //         getCardData();
  //         handleCloseCardDetail();
  //       })
  //       .catch((err) => {
  //         console.log(err, "err");
  //         errorToast(
  //           err?.response?.data?.error == "Request failed with status code 400"
  //             ? "You have entered wrong card details!"
  //             : err?.response?.data?.error ==
  //               "Request failed with status code 400"
  //             ? "You have entered wrong card details!"
  //             : err?.response?.data?.error
  //         );
  //       });
  //   } catch (err) {
  //     console.log(err, "errcatch");
  //     errorToast(err);
  //   }
  // };

  const handleCardDetailSubmit = (values) => {
    try {
      setLoader(true);
      saveCardFrameDetails(values)
        .then((res) => {
          setLoader(false);
          setFrameUrl(res?.data?.url);
          window.open(res?.data?.url);
          // successToast("Card details saved successfully!");
          // getCardData();
          handleCloseCardDetail();
          // setShowAddCardFrame(true);
        })
        .catch((err) => {
          setLoader(false);
          console.log(err, "err");
          errorToast(err?.response?.data?.error);
        });
    } catch (err) {
      setLoader(false);
      console.log(err, "errcatch");
      errorToast(err);
    }
  };

  useEffect(() => {
    fetch(page.current, page.pageSize);
    // eslint-disable-next-line
  }, [page.current]);

  const fetch = (pageNumber = 1, pageSize = 5) => {
    getSavingServices(pageNumber, pageSize)
      .then((res) => {
        setData(res?.data?.items);
        setPageData(res?.data);
        setPage({ ...page, totalItems: res?.data?.totalItems });
      })
      .catch((err) => {
        console.log(err, "error of getAll products....");
      });
  };

  useEffect(() => {
    fetch();
    // eslint-disable-next-line
  }, []);

  function convertMonthsToYears(months) {
    let years = Math.floor(months / 12);
    let remainingMonths = months % 12;

    if (years === 0) {
      return `${remainingMonths} ${t("months")}`;
    } else if (years === 1 && remainingMonths === 0) {
      return `1 ${t("year")}`;
    } else if (years === 1) {
      return `1 ${t("year")} ${remainingMonths} ${t("months")}`;
    } else if (remainingMonths === 0) {
      return `${years} ${t("years")}`;
    } else {
      return `${years} ${t("years")} ${remainingMonths} ${t("months")}`;
    }
  }

  const handlePurchase = (item) => {
    setShow(true);
    setServiceData(item);
  };

  const handleSubmit = (amount, type) => {
    if (
      amount < +serviceData?.minimumDepositAmount ||
      amount > +serviceData?.maximumDepositAmount
    ) {
      errorToast(
        `Amount must greater than ${serviceData?.minimumDepositAmount} and less than ${serviceData?.maximumDepositAmount}`
      );
    } else if (userData?.balance < amount && type !== "card") {
      errorToast(`You don't have enough balance`);
    } else {
      if (type === "wallet") {
        try {
          setLoader(true);
          buySavingService({
            investedAmount: amount,
            serviceId: serviceData?._id,
            paymentMedium: "Wallet",
          })
            .then((res) => {
              successToast("Program-service purchased successfully!");
              setLoader(false);
              handleClose();
              setAmount("");
              getUserData();
              fetch();
            })
            .catch((err) => {
              setLoader(false);
              console.log(err, "err");
            });
        } catch (err) {
          setLoader(false);
          console.log(err, "err");
          errorToast(err);
        }
      } else if (type === "card") {
        handleClose();
        setShowCardPay(true);
      }
    }
  };

  const handleAmountChange = (id, e) => {
    setAmount(e.target.value);
    setAmount1(e.target.value);
    getCalculatedData(e.target.value, id)
      .then((res) => {
        setCalculatedData(res?.data);
      })
      .catch((err) => {
        console.log(err, "error in getting calculations....");
      });
  };

  const removeAddedCardFunc = (item, document) => {
    try {
      removeAddedCard({
        card_token: item?.token,
        document_number: document,
      })
        .then((res) => {
          successToast("Card removed successfully!");
          getCardData();
        })
        .catch((err) => {
          console.log(err, "err");
          errorToast(err?.response?.data?.error?.message);
        });
    } catch (err) {
      console.log(err, "errcatch");
      errorToast(err);
    }
  };

  const submitWithdraw = (values) => {
    if (values?.amount > userData?.balance) {
      errorToast(`You don't have enough balance in your wallet!`);
    } else {
      try {
        setLoader(true);
        withdrawBalance({
          amount: values?.amount,
          bankAccount: values?.bankAccount,
          name: values?.name,
          ifscCode: values?.ifscCode,
        })
          .then((res) => {
            successToast(t("Withdraw Success"));
            getUserData();
            setLoader(false);
            handleWithdClose();
            getAllNotifications();
          })
          .catch((err) => {
            setLoader(false);
            console.log(err, "err");
          });
      } catch (err) {
        setLoader(false);
        console.log(err, "err");
        errorToast(err);
      }
    }
  };

  const handleAddbalSubmit = (data) => {
    try {
      setLoader(true);
      rechargeWallet(data)
        .then((res) => {
          successToast("Balance Added successfully!");
          getUserData();
          handleBalClose();
        })
        .catch((err) => {
          setLoader(false);
          console.log(err, "err");
        });
    } catch (err) {
      setLoader(false);
      console.log(err, "err");
      errorToast(err);
    }
  };

  const handleRecharge = async (values, resetForm) => {
    try {
      setLoader(true);
      await rechargeBalanceWallet(values)
        .then((res) => {
          setLoader(false);
          successToast("Balance Added successfully!");
          getUserData();
          handleBalClose();
          resetForm();
        })
        .catch((err) => {
          setLoader(false);
          console.log(err, "err");
        });
    } catch (err) {
      setLoader(false);
      console.log(err, "err");
      errorToast(err);
    }
  };

  const handlePayWithCard = (values) => {
    try {
      setLoader(true);
      buySavingServiceWithExistingCard({
        investedAmount: amount1,
        serviceId: serviceData?._id,
        cardToken: values?.cardToken,
        documentNumber: values?.documentNumber,
        cardNumber: values?.cardNumber,
      })
        .then((res) => {
          successToast("Program-service purchased successfully!");
          setLoader(false);
          handleCloseShowCard();
          setAmount("");
          getUserData();
          fetch();
        })
        .catch((err) => {
          setLoader(false);
          console.log(err, "err");
        });
    } catch (err) {
      setLoader(false);
      console.log(err, "err");
      errorToast(err);
    }
  };

  return (
    <>
      <div className="container-fluid programing">
        <div className="col-12 mt-4">
          <div className="row">
            <div className="col-lg-12  mb-3">
              <div className="m-auto">
                <div className="banner_bg">
                  <img
                    src={banner}
                    alt="profile"
                    className="img-fluid banner_img w-100"
                  />
                  <div className="header_text text-white">
                    <h1>
                      {t("Hello")} {userData?.firstName}!
                    </h1>
                    <p>{t("Welcome Back to Fintech Blok")}</p>
                  </div>
                </div>
                <div className="my-3">
                  {/* <span className="text-uppercase montserrat-bold letter-2 f-22">
                    {t("Programmed Savings Service")}
                  </span> */}
                </div>
                <div className="dashboard-overview pb-4">
                  <div className="borderGreen p-2"></div>
                  <div className="py-2 px-3 px-xl-5">
                    <div className="d-flex justify-content-between">
                      <Nav
                        variant="underline"
                        defaultActiveKey="deposit"
                        onSelect={handleTabSelect}
                      >
                        <Nav.Item>
                          <Nav.Link eventKey="deposit">
                            {t("Account Details")}
                          </Nav.Link>
                        </Nav.Item>
                        {/* <Nav.Item>
                          <Nav.Link eventKey="withdraw">Withdraw</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="send">Send</Nav.Link>
                        </Nav.Item> */}
                      </Nav>
                      <div>
                        <button
                          className="common-btn2 py-2 px-3 mx-2"
                          onClick={() => setshowWithdBal(true)}
                        >
                          {t("Withdraw")}
                        </button>

                        <button
                          className="common-btn2 py-2 mx-2 px-3"
                          onClick={() => setShowCardDetail(true)}
                        >
                          <strong>+</strong> {t("Card")}
                        </button>

                        <button
                          className="common-btn2 py-2 mx-2 px-3"
                          onClick={() =>
                            cardData?.length > 0
                              ? setShowAddBal(true)
                              : errorToast("Add Card First!!")
                          }
                        >
                          <strong>+</strong> {t("Add Balance")}
                        </button>
                        <button
                          className="common-btn2 py-2 mx-2 px-3"
                          id="refresh"
                          onClick={() => getCardData()}
                        >
                          <IoIosRefresh />
                          <Tooltip
                            placement="top"
                            target="refresh"
                            text="Refresh Card Listing"
                          />
                        </button>
                      </div>
                    </div>
                    <div className="mt-3">
                      {activeTab === "deposit" && (
                        <div className="mt-4">
                          <div className="row gy-2">
                            <div className="col-lg-4">
                              <div className="balance-card-bg">
                                <div className="d-flex flex-column justify-content-between gap-4">
                                  <div>
                                    <div className="d-flex justify-content-between align-items-center">
                                      <span className="montserrat-bold f-24 text-white">
                                        {t("Balance")}
                                      </span>
                                      <span className="text-white f-14 montserrat-bold ">
                                        {/* {t("Check balance")} */}
                                      </span>
                                    </div>
                                  </div>
                                  <div>
                                    <span className="f-44 montserrat-bold text-white">
                                      ${userData?.balance?.toFixed(2)}{" "}
                                    </span>
                                    <span className="text-white montserrat-bold f-18">
                                      USD
                                    </span>
                                  </div>
                                  {/* <div>
                                    <span className="text-white montserrat-bold f-14">
                                      Account No. : 523774
                                    </span>
                                  </div> */}
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-8 pe-0 card_img">
                              {cardLoader ? (
                                <CommonLoader />
                              ) : (
                                <CardSlider
                                  data={cardData}
                                  removeAddedCardFunc={removeAddedCardFunc}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                      {activeTab === "withdraw" && (
                        <div className="mt-4">Withdraw Content</div>
                      )}
                      {activeTab === "send" && (
                        <div className="mt-4">Send Content</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 mt-4">
          <div className="recent-transaction-div mt-5">
            <div className="d-flex justify-content-between mb-5">
              <div>
                <span className="text-uppercase  montserrat-bold letter-2 f-22">
                  {t("Purchase Service")}
                </span>
              </div>
            </div>
            <div className="custom-table mb-4">
              <table className="w-100">
                <tbody>
                  <tr>
                    <th>{t("Name")}</th>
                    <th>{t("Interest")}</th>
                    <th>{t("Tenure")}</th>
                    <th>{t("Locking Period")}</th>
                    <th>{t("Minimum Deposit")}</th>
                    <th>{t("Maximum Deposit")}</th>
                    <th>{t("Early Withdraw Panelty")}</th>
                    <th>{t("Action")}</th>
                  </tr>
                  {data?.map((item, i) => (
                    <tr key={i} className="mb-2">
                      <td className="montserrat-regular f-16">
                        {language === "sp" ? item.nameSp : item.name}
                      </td>
                      <td className="montserrat-regular f-16">
                        {item?.interestRate}%
                      </td>
                      <td className="montserrat-regular f-16">
                        {convertMonthsToYears(item?.tenure)}
                      </td>
                      <td className="montserrat-regular f-16">
                        {convertMonthsToYears(item?.lockingPeriod)}
                      </td>
                      <td className="montserrat-regular f-16">
                        ${item?.minimumDepositAmount}
                      </td>
                      <td className="montserrat-regular f-16">
                        ${item?.maximumDepositAmount}
                      </td>
                      <td className="montserrat-regular f-16">
                        {item?.earlyWithdrawalPenalty}%
                      </td>
                      <td>
                        {!item?.serviceInfo ? (
                          <button
                            className="received-button"
                            onClick={() => handlePurchase(item)}
                          >
                            {t("Invest")}
                          </button>
                        ) : (
                          <button className="received-button">
                            {t("Purchased")}
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {pageData?.totalItems > 5 && (
              <Pagination setPage={setPage} page={page} data={pageData} />
            )}
          </div>
        </div>
      </div>
      <PurchaseService
        show={show}
        setAmount={setAmount}
        setAmount1={setAmount1}
        check={check}
        setCheck={setCheck}
        amount={amount}
        handleClose={handleClose}
        serviceData={serviceData}
        handleSubmit={handleSubmit}
        calculatedData={calculatedData}
        handleAmountChange={handleAmountChange}
        loader={loader}
      />
      <AddBalanceModal
        show={showAddBal}
        amount={balAmount}
        show1={show1}
        setShow1={setShow1}
        setAmount={setBalAmount}
        handleClose1={handleClose1}
        handleAddbalSubmit={handleAddbalSubmit}
        handleRecharge={handleRecharge}
        handleClose={handleBalClose}
        cardData={cardData}
        loader={loader}
      />
      <WithdrawBalModal
        show={showWithdBal}
        amount={withdAmount}
        setAmount={setWithdAmount}
        handleClose={handleWithdClose}
        submitWithdraw={submitWithdraw}
      />
      <PaypalModal show={show1} amount={balAmount} handleClose={handleClose1} />
      <PayWithCard
        show={showCardPay}
        amount={amount1}
        handleCardPaySubmit={handleCardPaySubmit}
        handleClose={handleCloseShowCard}
        loader={loader}
        cardData={cardData}
        handlePayWithCard={handlePayWithCard}
      />
      <CardDetail2
        show={showCardDetail}
        handleCardDetailSubmit={handleCardDetailSubmit}
        handleClose={handleCloseCardDetail}
        loader={loader}
      />
      <AddCardFrame
        url={frameUrl}
        show={showAddCardFrame}
        handleClose={handleCloseAddCardFrame}
      />
    </>
  );
};

export default withTranslation()(Transfer);

Transfer.propTypes = {
  t: PropTypes.any,
};

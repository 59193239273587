import React from "react";
import BannerImg from "../../Assets/Images/homeBanner.png";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

const Banner = ({ t }) => {
  return (
    <div className="home_banner_page mt-2 p-4" id="banner">
      <div className="row">
        <div className="col-xl-7 col-sm-12 m-auto">
          <div className="banner_page_content">
            <h2 className="text-start">{t("Manage your Wallet in")} </h2>
            <h2 className="text-end">
              {t("The")} <span> {t("easiest")}</span> {"&"}{" "}
              <span>{t("best way")}</span>
            </h2>
            <p className="text-end">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry
            </p>
            <div
              className="banner_content_buttons d-flex gap-2 justify-content-center"
              style={{ margin: "30px 0px" }}
            >
              <button className="btn button_1">{t("Get Started")}</button>
              <button className="btn button_2">{t("How it work ?")}</button>
            </div>
          </div>
        </div>
        <div className="col-xl-5 col-sm-12 text-center">
          <img src={BannerImg} alt="banner" className="img-fluid" />
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(Banner);

Banner.propTypes = {
  t: PropTypes.any,
};

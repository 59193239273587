import React from 'react'
import PropTypes from "prop-types";
import { withTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const PageTitle = ({t}) => {
  const location = useLocation();
  const pathname = location?.pathname;
  const title = pathname.split("/").pop();
  return (
    <div className=" px-3 my-3">
 
      <span className="text-uppercase montserrat-bold letter-2 f-22">
        {t(`${title}`)}
      </span>
  </div>
  )
}

export default withTranslation()(PageTitle);
// export default PageTitle
PageTitle.propTypes = {
    t: PropTypes.any,
  };
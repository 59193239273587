import React from "react";
import "../../Assets/Css/Auth.css";
import Logo from "../../Assets/Images/Logo.svg";
import Form from "react-bootstrap/Form";
import LoginRight from "../../Assets/Images/CardDetail.png";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import "react-phone-input-2/lib/style.css";

const CardDetail = ({ t }) => {
  return (
    <div className="container my-5">
      <div className="row  h-100 border-layout">
        <div className="col-lg-6 AuthLeft bg-white py-5">
          <img src={Logo} alt="" width={280} className="mx-5" />

          <div className="d-flex justify-content-center mb-5 fs-2">
            <strong>Card Details</strong>
          </div>
          <Form className="px-5">
            <Form.Group controlId="validationCustom02" className="my-3">
              <Form.Label>Card Number</Form.Label>
              <Form.Control required type="text" placeholder="Card Number" />
            </Form.Group>
            <div className="row">
              <Form.Group controlId="validationCustom01" className="col-6">
                <Form.Label>Expiry Date</Form.Label>
                <Form.Control required type="text" placeholder="MM/YY" />
              </Form.Group>

              <Form.Group controlId="validationCustom02" className="col-6">
                <Form.Label>CVV</Form.Label>
                <Form.Control required type="text" placeholder="CVV" />
              </Form.Group>
            </div>
            <button type="submit" className="w-100 my-4 common-btn fw-bold">
              {t("Add Card")}
            </button>
          </Form>
        </div>
        <div className="col-lg-6 AuthRight p-5 d-flex justify-content-center align-items-center">
          <img src={LoginRight} alt="Login" className="img-fluid" />
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(CardDetail);

CardDetail.propTypes = {
  t: PropTypes.any,
};

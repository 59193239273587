import React, { useState } from "react";
import { createContext } from "react";
import { getUser } from "./services/user";
import { getNotifications } from "./services/user";
import { getFrameCardDetails } from "./services/cardServices";

export const UserContext = createContext();
const Context = ({ children }) => {
  const [pageContLoader, setPageContLoader] = useState(false);
  const [cardLoader, setCardLoader] = useState(false);
  const [language, setLanguage] = useState(
    localStorage.getItem("I18N_LANGUAGE") || "en"
  );
  const [userData, setUserData] = useState("");
  const [notificationCount, setNotificationCount] = useState([]);
  const [cardData, setCardData] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const getUserData = () => {
    getUser()
      .then((res) => {
        setUserData(res?.data);
      })
      .catch((err) => {
        console.log(err, "err of user data ");
      });
  };

  const getCardData = () => {
    setCardLoader(true);
    getFrameCardDetails()
      .then((res) => {
        setCardData(res?.data);
        setCardLoader(false);
      })
      .catch((err) => {
        console.log(err, "err of user data ");
        setCardData([]);
        setCardLoader(false);
      });
  };

  const getAllNotifications = () => {
    getNotifications()
      .then((res) => {
        setNotifications(res?.data?.getData);
        setNotificationCount(res?.data?.totalUnRead);
      })
      .catch((err) => {
        console.log(err, "err of user data ");
        setNotificationCount([]);
      });
  };

  return (
    <UserContext.Provider
      value={{
        pageContLoader,
        setPageContLoader,
        cardData,
        userData,
        getUserData,
        getCardData,
        notifications,
        getAllNotifications,
        notificationCount,
        cardLoader,
        language,
        setLanguage,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default Context;

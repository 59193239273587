import React, { useContext, useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { withTranslation } from "react-i18next";
import { Input, FormGroup, Label } from "reactstrap";
import { UserContext } from "../../context";

const SwitchPaymentModal = ({
  data,
  show,
  handleClose,
  handleSwitchMethod,
  method,
  setMethod,
  t,
}) => {
  const { cardData, getCardData } = useContext(UserContext);

  const [document, setDocument] = useState("");
  const [card, setCard] = useState("");
  const [cardNumber, setCardNumber] = useState("");

  const handleChange = (e) => {
    setMethod(e?.target?.value);
  };

  useEffect(() => {
    getCardData();
    // eslint-disable-next-line
  }, []);

  const handleSubmit = () => {
    const submitData =
      method === "wallet"
        ? { type: method }
        : {
            type: method,
            document: document,
            card: card,
            cardNumber: cardNumber,
          };
    handleSwitchMethod(submitData);
  };
  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={handleClose}
    >
      <Modal.Header closeButton className="montserrat-bold">
        <h5>{t("Switch Payment Method")}</h5>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="selected-payment-method mb-2">
            <div>
              <h6>Selected Method:</h6>

              {data?.service?.paymentType === "WALLET" ? (
                <h5>Wallet</h5>
              ) : (
                <h5 className="m-0">Card : {data?.service?.cardNumber}</h5>
              )}
            </div>
          </div>

          <div className="" style={{ padding: "10px" }}>
            {data?.service?.paymentType === "CARD" ? (
              <div>
                <FormGroup tag="fieldset" className="">
                  <h6 className="me-4">{t("Select New Payment Method")}</h6>
                  <div className="d-flex gap-4">
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="method"
                          checked={method === "wallet"}
                          value={"wallet"}
                          onChange={handleChange}
                        />
                        {t("Wallet")}
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input
                          name="method"
                          type="radio"
                          checked={method === "card"}
                          value={"card"}
                          onChange={handleChange}
                        />
                        {t("Card")}
                      </Label>
                    </FormGroup>
                  </div>
                </FormGroup>
              </div>
            ) : (
              <div>
                <FormGroup tag="fieldset" className="">
                  <h6 className="me-4">{t("Select New Payment Method")}</h6>
                  <div className="d-flex gap-4">
                    <FormGroup check>
                      <Label check>
                        <Input
                          name="method"
                          type="radio"
                          checked={method === "card"}
                          value={"card"}
                          onChange={handleChange}
                        />
                        {t("Card")}
                      </Label>
                    </FormGroup>
                  </div>
                </FormGroup>
              </div>
            )}
            {method === "card" && (
              <div>
                <FormGroup tag="fieldset" className="">
                  <h6 className="me-4">{t("Select Card")}</h6>
                  <div className="">
                    <select
                      className="form-control"
                      onChange={(e) => {
                        setDocument(e.target.value);
                        setCard(
                          e.target.options[e.target.selectedIndex].getAttribute(
                            "token"
                          )
                        );
                        setCardNumber(
                          e.target.options[e.target.selectedIndex].getAttribute(
                            "cardNumber"
                          )
                        );
                      }}
                      value={document}
                    >
                      <option value="">--{t("Select")}-</option>
                      {cardData?.map((cardItem) =>
                        cardItem?.cards?.map((item, i) => (
                          <option
                            value={cardItem?.document}
                            token={item?.token}
                            key={i}
                            cardNumber={item?.number}
                          >
                            {item?.number}
                          </option>
                        ))
                      )}
                    </select>
                  </div>
                </FormGroup>
              </div>
            )}

            <div className="text-center">
              <button
                className="common-btn2 py-2 px-3"
                disabled={
                  method === "" || (method === "card" && document === "")
                }
                onClick={handleSubmit}
              >
                {t("Update")}
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default withTranslation()(SwitchPaymentModal);

import { Outlet, Navigate } from "react-router-dom";
import { useAuth, useVerified } from "../useAuth";

function PublicRoutes() {
  const token = useAuth();
  const kyc = useVerified();

  return token && kyc ? (
    <Navigate to="/" />
  ) : token ? (
    <Navigate to="/user-kyc" />
  ) : (
    <Outlet />
  );
}

export default PublicRoutes;

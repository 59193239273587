import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../Assets/Css/Auth.css";
import Logo from "../../Assets/Images/Logo.svg";
import Form from "react-bootstrap/Form";
import LoginRight from "../../Assets/Images/UserKyc.png";
import Pdf from "../../Assets/Images/pdf.png";
import { getKycDocument, verifyKyc, getKycAllInfo } from "../../services/user";
import { CloudUploadOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";
import { API_BASE_URL, API_IMAGE_URL } from "../../helpers/apis_helper";
import { Spinner } from "reactstrap";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

const { Dragger } = Upload;

const UserKyc = ({ t }) => {
  const navigate = useNavigate();
  const [isLoading, setLoader] = useState(false);
  const [uploadedFile, setUploadedFile] = useState("");
  const local = localStorage.getItem("fintech-user");
  const user = local ? JSON.parse(local) : {};
  const name = user.firstName || "";
  const [address, setAddress] = useState("");
  const [kycData, setKycData] = useState();

  const getKycInfo = () => {
    getKycAllInfo()
      .then((res) => {
        setKycData(res?.data);
      })
      .catch((err) => {
        console.log(err, "error of getAll products....");
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {
      documentId: uploadedFile,
      kycCode: "INPROGRESS",
      address: address,
      name: name,
    };

    if (uploadedFile === "") {
      message.error("Please upload the Document!");
    } else {
      verifyKyc(data)
        .then((res) => {
          message.success(t("Your Request is initiated"));
          localStorage.removeItem("fintech-user");
          navigate("/home");
        })
        .catch((err) => {
          message.error(err?.message);
        });
    }
  };

  const [kycDocument, setKycDocument] = useState("");
  useEffect(() => {
    getKycDocument()
      .then((Res) => {
        setKycDocument(Res?.data?.kycDocument);
      })
      .catch((Err) => {
        console.log(Err, "err");
      });
  }, []);

  useEffect(() => {
    getKycInfo();
  }, []);

  const props = {
    name: "file",
    accept: ".pdf",
    multiple: false,
    action: `${API_BASE_URL}/client/saveImage`,
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
        setUploadedFile(info.file.name);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const handleDownload = () => {
    setLoader(true);
    fetch(API_IMAGE_URL + kycDocument)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", API_IMAGE_URL + kycDocument);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setLoader(false);
      })
      .catch((error) => {
        console.error("Error downloading PDF:", error);
        setLoader(false);
      });
  };

  return (
    <div className="container my-5">
      <div className="row  h-100 border-layout">
        <div className="col-lg-6 AuthLeft bg-white py-5">
          <img className="mx-5" src={Logo} alt="logo" width={280} />
          <div className="d-flex justify-content-center mb-2 fs-2">
            <strong>Complete your KYC</strong>
          </div>
          {kycData?.status === "REJECTED" && (
            <div className="px-5 mb-2 ">
              <h6>KYC rejected by admin! Please complete your KYC again.</h6>
              <h6>KYC rejection reason- {kycData?.rejectReason}</h6>
            </div>
          )}

          <Form onSubmit={handleSubmit} className="px-5">
            <Form.Group controlId="validationCustom01">
              <Form.Label>Name</Form.Label>
              <Form.Control
                // disabled
                required
                type="text"
                value={name}
                placeholder="Name"
              />
            </Form.Group>
            <Form.Group controlId="validationCustom02" className="my-3">
              <Form.Label>Address</Form.Label>
              <Form.Control
                onChange={(e) => setAddress(e.target.value)}
                required
                type="text"
                placeholder="Address"
              />
            </Form.Group>
            <Form.Group controlId="validationCustom02" className="my-3">
              <Form.Label>KYC Form</Form.Label>
              <br />
              <div onClick={handleDownload} style={{ cursor: "pointer" }}>
                <span className="pdf-button my-3">
                  {isLoading ? (
                    <Spinner color="light" size="sm">
                      Loading...
                    </Spinner>
                  ) : (
                    <img alt="pdf" src={Pdf} style={{ width: "24px" }} />
                  )}{" "}
                  Download
                </span>
              </div>
            </Form.Group>
            <Dragger {...props}>
              <p className="ant-upload-drag-icon d-flex justify-content-center align-items-center">
                <CloudUploadOutlined /> <span className="ms-3">Upload</span>
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
            </Dragger>
            <button type="submit" className="w-100 my-3 common-btn fw-bold">
              Upload
            </button>
          </Form>
        </div>
        <div className="col-lg-6 AuthRight p-5 d-flex justify-content-center align-items-center">
          <img src={LoginRight} alt="Login" className="img-fluid" />
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(UserKyc);

UserKyc.propTypes = {
  t: PropTypes.any,
};

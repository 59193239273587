import axios from "axios";
import { downloadFile } from "../Utils/common";

export const API_BASE_URL = "https://dev-apis.fintechblok.com/api";
export const API_IMAGE_URL = "https://dev-apis.fintechblok.com/uploads/common/";

const API_URL = "https://dev-apis.fintechblok.com/api";

export const axiosApi = axios.create({
  baseURL: API_URL,
});

export async function get(url, config = {}) {
  return await axiosApi
    .get(url, { ...config })
    .then((response) => response.data);
}

export async function getWithExportFile(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then((response) => {
    if (response.status && !response.data.data) {
      const fileUrl = URL.createObjectURL(
        new Blob([response.data], { type: `text/csv` })
      );
      downloadFile(fileUrl, `${url.split("/")[1]}_${Date.now()}.csv`);
    }
    return response.data;
  });
}

// export async function postWithExportFile(url, data, config = {}) {
//   return await axiosApi
//     .post(url, { ...data }, { ...config })
//     .then((response) => {
//       if (response.status && !response.data.data) {
//         const fileUrl = URL.createObjectURL(
//           new Blob([response.data], { type: `text/csv` })
//         );
//         downloadFile(fileUrl, `${url.split("/")[1]}_${Date.now()}.csv`);
//       }
//       return response.data;
//     });
// }

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function postAsForm(url, data, config = {}) {
  return axiosApi
    .post(url, data, { ...config })
    .then((response) => response.data);
}

export async function putAsForm(url, data, config = {}) {
  return axiosApi
    .put(url, data, { ...config })
    .then((response) => response.data);
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function del(url, id, config = {}) {
  return await axiosApi
    .delete(
      url,
      {
        params: { id },
      },
      { ...config }
    )
    .then((response) => response.data);
}

// export const apiHeader = () => {
//   let obj = "";
//   if (typeof window !== "undefined") {
//     obj = JSON.parse(localStorage.getItem("fintech-user"));
//   }
//   if (obj && obj?.token) {
//     axiosApi.defaults.headers.common["Authorization"] = obj?.token;
//   } else {
//     return {};
//   }
// };

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // Handle the session expiry
      handleSessionExpiry();
    }
    return Promise.reject(error);
  }
);

const handleSessionExpiry = () => {
  // errorToast("Session Expired!");
  // Clear any authentication tokens or user data here
  localStorage.removeItem("fintech-user");
  // Redirect to the home page
  window.location.href = "/home";
};

axiosApi.interceptors.request.use(
  (config) => {
    let obj = JSON.parse(localStorage.getItem("fintech-user"));
    const token = obj?.token;
    if (token) {
      config.headers.Authorization = `${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

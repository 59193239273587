import { Outlet, Navigate } from "react-router-dom";
import { useAuth, useVerified } from "../useAuth";

function PrivateRoutes() {
  const token = useAuth();
  const kyc = useVerified();

  return token && kyc ? (
    <Outlet />
  ) : token ? (
    <Navigate to="/user-kyc" />
  ) : (
    <Navigate to="/home" />
  );
}

export default PrivateRoutes;

import moment from "moment";

export const downloadFile = (dataurl, filename) => {
  const a = document.createElement("a");
  a.href = dataurl;
  a.setAttribute("download", filename);
  a.click();
};

export const StandardPickerDateFormat = (Date) => {
  return moment(Date).format("MMM DD, YYYY");
};

import React from "react";
import "../../Assets/Css/Auth.css";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import * as Yup from "yup";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

const CardDetail2 = ({
  show,
  handleClose,
  handleCardDetailSubmit,
  loader,
  t,
}) => {
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      holder_name: "",
      document_number: "",
      document_type: "",
      address: "",
    },
    validationSchema: Yup.object({
      document_number: Yup.string().required(t("Required")),
      holder_name: Yup.string().required(t("Required")),
      document_type: Yup.string().required(t("Required")),
      address: Yup.string().required(t("Required")),
    }),
    onSubmit: async (values) => {
      handleCardDetailSubmit(values);
    },
  });

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      // size="lg"
      className="card-details-modal"
      show={show}
      onHide={handleClose}
    >
      <Modal.Header closeButton className="montserrat-bold">
        <h5>{t("Add Card")}</h5>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          <div className="row h-100">
            <div className="AuthLeft bg-white">
              <Form className="row" onSubmit={validation.handleSubmit}>
                <Form.Group
                  controlId="validationCustom02"
                  className="mb-3 col-12"
                >
                  <Form.Label>{t("Name")}</Form.Label>
                  <Form.Control
                    name="holder_name"
                    value={validation.values.holder_name}
                    onChange={validation.handleChange}
                    type="text"
                    placeholder=""
                  />
                  {validation.touched.holder_name &&
                  validation.errors.holder_name ? (
                    <div style={{ color: "red" }}>
                      {validation.errors.holder_name}
                    </div>
                  ) : null}
                </Form.Group>
                <Form.Group
                  controlId="validationCustom02"
                  className="mb-3 col-6"
                >
                  <Form.Label>{t("Document Type")}</Form.Label>
                  <Form.Select
                    name="document_type"
                    onChange={validation.handleChange}
                    value={validation.values.document_type}
                    aria-label="Default select example"
                  >
                    <option value="">--{t("Select")}--</option>
                    <option value="04">RUC</option>
                    <option value="05">Ecuadorian ID card</option>
                    <option value="06">Passport</option>
                    <option value="08">Identification of the exterior</option>
                  </Form.Select>

                  {validation.touched.document_type &&
                  validation.errors.document_type ? (
                    <div style={{ color: "red" }}>
                      {validation.errors.document_type}
                    </div>
                  ) : null}
                </Form.Group>
                <Form.Group
                  controlId="validationCustom02"
                  className="col-6 mb-3"
                >
                  <Form.Label>{t("Document Number")}</Form.Label>
                  <Form.Control
                    value={validation?.values?.document_number?.replace(
                      /[^0-9]/g,
                      ""
                    )}
                    // minLength={8}
                    // maxLength={20}
                    onChange={validation.handleChange}
                    name="document_number"
                    type="text"
                    placeholder=""
                  />
                  {validation.touched.document_number &&
                  validation.errors.document_number ? (
                    <div style={{ color: "red" }}>
                      {validation.errors.document_number}
                    </div>
                  ) : null}
                </Form.Group>
                <Form.Group
                  controlId="validationCustom02"
                  className="mb-3 col-12"
                >
                  <Form.Label>{t("Address")}</Form.Label>
                  <Form.Control
                    name="address"
                    value={validation.values.address}
                    onChange={validation.handleChange}
                    type="text"
                    placeholder=""
                  />
                  {validation.touched.address && validation.errors.address ? (
                    <div style={{ color: "red" }}>
                      {validation.errors.address}
                    </div>
                  ) : null}
                </Form.Group>
                <button
                  type="submit"
                  disabled={loader}
                  className="w-100 my-4 common-btn fw-bold"
                >
                  {loader ? (
                    <span className="typeWriter">
                      Loading<span> . . . . .</span>
                    </span>
                  ) : (
                    t("Save")
                  )}
                </button>
              </Form>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default withTranslation()(CardDetail2);

CardDetail2.propTypes = {
  t: PropTypes.any,
};

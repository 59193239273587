import React from "react";
import "./loader.css";
const PageLoader = ({ hidden }) => {
  return (
    <div className="page-loader-container" hidden={hidden}>
      <div className="donut"> </div>
    </div>
  );
};

export default PageLoader;

import React, { useState } from "react";
import "../../Assets/Css/Auth.css";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import * as Yup from "yup";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import "react-phone-input-2/lib/style.css";

const PayWithCard = ({
  show,
  amount,
  handleClose,
  handleCardPaySubmit,
  loader,
  cardData,
  handlePayWithCard,
  t,
}) => {
  const [newCard, setNewCard] = useState(false);
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      cardNumber: "",
      securityCode: "",
      expiryDate: "",
      // countryCode: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required(t("Required")),
      cardNumber: Yup.string().required(t("Required")),
      securityCode: Yup.string().required(t("Required")),
      expiryDate: Yup.string()
        .required(t("Required"))
        .matches(
          /^\d{4}-(0[1-9]|1[0-2])$/,
          "Expiry date must be in the format YYYY-MM"
        ),
      // countryCode: Yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      let apiData = {
        purchase_units: [
          {
            amount: {
              currency_code: "USD",
              value: amount,
            },
          },
        ],
        intent: "CAPTURE",
        payment_source: {
          card: {
            name: values?.name,
            number: values?.cardNumber,
            security_code: values?.securityCode,
            expiry: values?.expiryDate,
            billing_address: {
              country_code: "US",
            },
          },
        },
      };

      handleCardPaySubmit(apiData);
    },
  });
  const validation1 = useFormik({
    enableReinitialize: true,
    initialValues: {
      documentNumber: "",
    },
    validationSchema: Yup.object({
      documentNumber: Yup.string().required(t("Required")),
    }),
    onSubmit: async (values) => {
      // handleAddBalCard(values);
      handlePayWithCard(values);
    },
  });
  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={handleClose}
    >
      <Modal.Header closeButton className="montserrat-bold">
        <h5>{t("Purchase Program Saving Service")}</h5>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          <div className="row h-100">
            <div className="AuthLeft bg-white">
              {cardData?.length > 0 && !newCard ? (
                <Form className={""} onSubmit={validation1.handleSubmit}>
                  <Form.Group
                    controlId="validationCustom02"
                    className="mb-3 col-12"
                  >
                    <Form.Label>{t("Card Type")}</Form.Label>
                    <Form.Select
                      name="documentNumber"
                      // onChange={validation1?.handleChange}
                      onChange={(e) => {
                        validation1.setFieldValue(
                          "documentNumber",
                          e.target.value
                        );
                        validation1.setFieldValue(
                          "cardToken",
                          e.target.options[e.target.selectedIndex].getAttribute(
                            "token"
                          )
                        );
                        validation1.setFieldValue(
                          "cardNumber",
                          e.target.options[e.target.selectedIndex].getAttribute(
                            "cardNumber"
                          )
                        );
                      }}
                      value={validation1?.values.document}
                      aria-label="Default select example"
                    >
                      <option value="">--{t("Select")}-</option>
                      {cardData?.map((cardItem) =>
                        cardItem?.cards?.map((item, i) => (
                          <option
                            value={cardItem?.document}
                            token={item?.token}
                            cardNumber={item?.number}
                            key={i}
                          >
                            {item?.number}
                          </option>
                        ))
                      )}
                    </Form.Select>

                    {validation1?.touched?.documentNumber &&
                    validation1?.errors?.documentNumber ? (
                      <div style={{ color: "red" }}>
                        {validation1?.errors?.documentNumber}
                      </div>
                    ) : null}
                  </Form.Group>
                  <div className="d-flex justify-content-between">
                    {/* <button
                      className="w-100 mb-3 common-btn fw-bold"
                      type="button"
                      onClick={() => setNewCard(true)}
                    >
                      {t("New Card")}
                    </button> */}
                    <button
                      className="w-100 mb-3 common-btn fw-bold"
                      type="submit"
                      disabled={loader}
                    >
                      {loader ? (
                        <span className="typeWriter">
                          Loading<span> . . . . .</span>
                        </span>
                      ) : (
                        t("Click to Proceed")
                      )}
                    </button>
                  </div>
                </Form>
              ) : (
                <Form className="" onSubmit={validation.handleSubmit}>
                  <Form.Group controlId="validationCustom02" className="my-3">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      name="name"
                      value={validation.values.name}
                      onChange={validation.handleChange}
                      type="text"
                      placeholder="Name"
                    />
                    {validation.touched.name && validation.errors.name ? (
                      <div style={{ color: "red" }}>
                        {validation.errors.name}
                      </div>
                    ) : null}
                  </Form.Group>
                  <div className="row my-3">
                    <Form.Group
                      controlId="validationCustom02"
                      className="col-12"
                    >
                      <Form.Label>Card Number</Form.Label>
                      <Form.Control
                        onChange={validation.handleChange}
                        name="cardNumber"
                        type="text"
                        placeholder="Card Number"
                      />
                      {validation.touched.cardNumber &&
                      validation.errors.cardNumber ? (
                        <div style={{ color: "red" }}>
                          {validation.errors.cardNumber}
                        </div>
                      ) : null}
                    </Form.Group>
                    {/* <Form.Group controlId="validationCustom02" className="col-6">
                    <Form.Label>Country Code</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      name="countryCode"
                      onChange={validation.handleChange}
                      placeholder="Country Code"
                    />
                    {validation.touched.countryCode &&
                    validation.errors.countryCode ? (
                      <div style={{ color: "red" }}>
                        {validation.errors.countryCode}
                      </div>
                    ) : null}
                  </Form.Group> */}
                  </div>
                  <div className="row my-3">
                    <Form.Group
                      controlId="validationCustom01"
                      className="col-6"
                    >
                      <Form.Label>Expiry Date</Form.Label>
                      <Form.Control
                        name="expiryDate"
                        type="text"
                        onChange={validation.handleChange}
                        placeholder="YYYY-MM"
                      />
                      {validation.touched.expiryDate &&
                      validation.errors.expiryDate ? (
                        <div style={{ color: "red" }}>
                          {validation.errors.expiryDate}
                        </div>
                      ) : null}
                    </Form.Group>

                    <Form.Group
                      controlId="validationCustom02"
                      className="col-6"
                    >
                      <Form.Label>Security Code</Form.Label>
                      <Form.Control
                        name="securityCode"
                        onChange={validation.handleChange}
                        type="text"
                        placeholder="Security Code"
                      />
                      {validation.touched.securityCode &&
                      validation.errors.securityCode ? (
                        <div style={{ color: "red" }}>
                          {validation.errors.securityCode}
                        </div>
                      ) : null}
                    </Form.Group>
                  </div>
                  <div className="d-flex justify-content-between">
                    {cardData?.length > 0 && (
                      <button
                        className="w-100 my-4 common-btn fw-bold mx-1"
                        type="button"
                        onClick={() => setNewCard(false)}
                      >
                        Select Card
                      </button>
                    )}
                    <button
                      type="submit"
                      className="w-100 my-4 common-btn fw-bold mx-1"
                      disabled={loader}
                    >
                      Pay
                    </button>
                  </div>
                </Form>
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default withTranslation()(PayWithCard);

PayWithCard.propTypes = {
  t: PropTypes.any,
};

export const errorMsgHandler = (err) => {
  return err.response.data
    ? err.response.data.error === "Incorrect password"
      ? "Incorrect password"
      : err.response.data.error === "Invalid Email"
      ? "Invalid Email"
      : err.response.data.error === "OTP does not match"
      ? "OTP does not match"
      : err.response.data.error === "Unauthorized"
      ? "Unauthorize"
      : err.response.data.error
    : err.code === "ERR_NETWORK"
    ? "Network Error"
    : err.message
    ? err.message
    : err;
};

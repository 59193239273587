import React from "react";
import "./header.css";
import LanguageDropdown from "./LanguageDropdown";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import NotificationDropdown from "./NotificationDropdown";
import ProfileDropdown from "./ProfileDropdown";

const Header = () => {
  return (
    <div className="container-fluid ">
      <div className="col-12">
        <div className="row">
          <div className="d-flex justify-content-end bg-box-light mb-md-4">
            <div className="col-8 pe-4"></div>
            <div className="col-4">
              <div className="d-flex justify-content-end gap-2 fs-4 py-2 align-items-center">
                <LanguageDropdown />
                <NotificationDropdown />
                <ProfileDropdown />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(Header);

Header.propTypes = {
  t: PropTypes.any,
};

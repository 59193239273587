import React from "react";
import Modal from "react-bootstrap/Modal";
import { withTranslation } from "react-i18next";
import moment from "moment";

const ProgramDetailModal = ({ show, handleClose, data, t }) => {
  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={handleClose}
    >
      <Modal.Header closeButton className="montserrat-bold">
        <h5> {t("Program Saving Service Detail")}</h5>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="row">
            <div className="col-6">
              <h6>{t("Installment Amount")}</h6>
            </div>
            <div className="col-6">
              <p>${data?.installmentAmount}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <h6>{t("Interest Amount")}</h6>
            </div>
            <div className="col-6">
              <p>${data?.interestAmount?.toFixed(2)}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <h6>{t("Last Paid")}</h6>
            </div>
            <div className="col-6">
              <p>{moment(data?.lastPaymentDate).format("lll")}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <h6>{t("Total Installment Paid")}</h6>
            </div>
            <div className="col-6">
              <p>{data?.totalInstallmentPaid}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <h6>{t("Total Amount")}</h6>
            </div>
            <div className="col-6">
              <p>${data?.totalAmount}</p>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default withTranslation()(ProgramDetailModal);

/* eslint-disable */
import React from "react";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { IoPlayBackSharp } from "react-icons/io5";
import { IoMdFastforward } from "react-icons/io";

const Pagination = ({ setPage, page, data }) => {
  const handleNext = () => {
    setPage((prevPage) => ({
      ...prevPage,
      current: Math.min(prevPage.current + 1, totalPages),
    }));
  };

  const handlePrev = () => {
    setPage((prevPage) => ({
      ...prevPage,
      current: Math.max(prevPage.current - 1, 1),
    }));
  };

  const handleGoToFirst = () => {
    setPage((prevPage) => ({
      ...prevPage,
      current: 1,
    }));
  };

  const totalPages = Math.ceil(data?.totalItems / page.pageSize);

  const handleGoToLast = () => {
    setPage((prevPage) => ({
      ...prevPage,
      current: totalPages,
    }));
  };

  return (
    <div>
      <div className="row mt-lg-5 mt-4">
        <div className="col-lg-8 col-xxl-7 d-flex align-items-center justify-content-between">
          <p className="mb-0 f-14 d-lg-block d-none">
            {/* Showing {data?.items?.length} of {page.totalItems} entries */}
            Showing{" "}
            {data?.items?.length > 0
              ? (data?.pageNumber - 1) * data?.pageSize + 1
              : "0"}{" "}
            to {(data?.pageNumber - 1) * data?.pageSize + data?.items?.length}{" "}
            of {page.totalItems} entries
          </p>
          {totalPages > 1 && (
            <nav className="mr-auto" aria-label="...">
              <ul className="pagination cstm-page">
                <li
                  className={`page-item ${page.current <= 1 ? "disabled" : ""}`}
                  onClick={handleGoToFirst}
                >
                  <span className="page-link">
                    <IoPlayBackSharp />
                  </span>
                </li>
                <li
                  className={`page-item ${page.current <= 1 ? "disabled" : ""}`}
                  onClick={handlePrev}
                >
                  <span className="page-link">
                    <BsChevronLeft />
                  </span>
                </li>
                <li className="page-item">
                  <a className="page-link" href="javascript:void(0);">
                    Page
                  </a>
                </li>
                {/* <li className="page-item active" aria-current="page">
                  <span className="page-link">{page.current}</span>
                </li> */}
                <li className="page-item" aria-current="page">
                  <span className="page-link">
                    {page.current} of {totalPages}
                  </span>
                </li>
                {/* <li className="page-item">
                  <a className="page-link" href="javascript:void(0);">
                    {totalPages}
                  </a>
                </li> */}
                <li
                  className={`page-item ${
                    page.current >= totalPages ? "disabled" : ""
                  }`}
                  onClick={handleNext}
                >
                  <a className="page-link" href="javascript:void(0);">
                    <BsChevronRight />
                  </a>
                </li>
                <li
                  className={`page-item ${
                    page.current >= totalPages ? "disabled" : ""
                  }`}
                  onClick={handleGoToLast}
                >
                  <a className="page-link" href="javascript:void(0);">
                    <IoMdFastforward />
                  </a>
                </li>
              </ul>
            </nav>
          )}
        </div>
      </div>
    </div>
  );
};

export default Pagination;

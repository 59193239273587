import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../context";
import "./programming.css";
import {
  getDetailInvestedSavingServices,
  changePaymentMethodForService,
  payDuePayment,
} from "../../services/cardServices";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import SwitchPaymentModal from "./SwitchPaymentModal";
import moment from "moment";
import { Badge } from "reactstrap";
import { errorToast, successToast } from "../../common/toaster";

const ViewPurchasedService = ({ t }) => {
  const location = useLocation();
  const { setPageContLoader } = useContext(UserContext);
  const language = localStorage.getItem("i18nextLng");
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const id = location.state.id;
  const [method, setMethod] = useState("");

  const handleClose = () => {
    setShow(false);
  };

  const fetch = () => {
    setPageContLoader(true);
    getDetailInvestedSavingServices(id)
      .then((res) => {
        setPageContLoader(false);
        setData(res?.data);
      })
      .catch((err) => {
        setPageContLoader(false);
        console.log(err, "error of getAll products....");
      });
  };

  useEffect(() => {
    fetch();
    // eslint-disable-next-line
  }, []);

  const handleSwitchMethod = (data) => {
    try {
      changePaymentMethodForService({ ...data, serviceId: id })
        .then((res) => {
          successToast("Payment Method Changed successfully!");
          setMethod("");
          handleClose();
          fetch();
        })
        .catch((err) => {
          console.log(err, "err");
          errorToast(err?.response?.data?.error?.message);
        });
    } catch (err) {
      console.log(err, "errcatch");
      errorToast(err);
    }
  };

  const handleDuePayment = (item) => {
    try {
      payDuePayment({ transactionId: item?._id })
        .then((res) => {
          successToast("Success!");
          fetch();
        })
        .catch((err) => {
          console.log(err, "err");
          errorToast(err?.response?.data?.error);
        });
    } catch (err) {
      errorToast(err?.response?.data?.error);
    }
  };

  const dataForBoxes = [
    {
      name: "Start Date",
      value: `${moment(data?.service?.startDate).format("ll")}`,
    },
    {
      name: "End Date",
      value: `${moment(data?.service?.endDate).format("ll")}`,
    },
    {
      name: "Installment Amount",
      value: `$${data?.service?.amount}`,
    },
    {
      name: "Total Investment Amount",
      value: `$${data?.totalInvestmentAmount}`,
    },
  ];

  const handleShowSwitchPayment = () => {
    setShow(true);
  };

  return (
    <div className="container-fluid">
      <div className="row detail_boxes_div">
        {dataForBoxes?.map((item, i) => (
          <div className="col-3" key={i}>
            <div className="detail_boxes">
              <p>{item?.name}</p>
              <span>{item?.value}</span>
            </div>
          </div>
        ))}
      </div>
      {data?.service?.status !== "COMPLETED" && (
        <div className="my-4">
          <button
            className="common-btn2 py-2 px-3"
            onClick={handleShowSwitchPayment}
          >
            Switch Payment Method
          </button>
        </div>
      )}

      {/* <div className="my-4 text-end">
        <button className="btn btn-danger p-2">Terminate Plan</button>
      </div> */}
      <div className="my-4">
        <table className="w-100 service-detail-table">
          <thead>
            <h6 className="my-2">Transactions Table</h6>
          </thead>
          <tbody>
            <tr>
              <th>{t("Amount")}</th>
              <th>{t("Date")}</th>
              <th>{t("Method")}</th>
              <th>{t("Type")}</th>
              <th>{t("Card Number")}</th>
              <th>{t("Status")}</th>
              <th>{t("Action")}</th>
            </tr>
            {data?.transactions?.length > 0 ? (
              data?.transactions?.map((item, i) => (
                <tr key={i} className="mb-2">
                  <td className="montserrat-regular f-16">
                    {item?.amount?.toFixed(2)}
                  </td>
                  <td className="montserrat-regular f-16">
                    {moment(item?.updatedAt).format("lll")}
                  </td>
                  <td className="montserrat-regular f-16">
                    {item?.paymentMedium}
                  </td>
                  <td className="montserrat-regular f-16">
                    {item?.transactionType == "Buy_Service"
                      ? "Debit"
                      : item?.transactionType == "Complete_Service"
                      ? "Credit"
                      : item?.transactionType == "Sell_Service"
                      ? "Terminated"
                      : "-"}
                  </td>
                  <td className="montserrat-regular f-16">
                    {item?.cardnumber ? item?.cardnumber : "-"}
                  </td>
                  <td className="montserrat-regular f-16">
                    <Badge
                      color={
                        item?.status == "PENDING"
                          ? "success"
                          : item?.status == "FAILED"
                          ? "danger"
                          : "info"
                      }
                      className="me-1 p-2"
                    >
                      {item?.status === "PENDING"
                        ? t("COMPLETED")
                        : item?.status}
                    </Badge>
                  </td>

                  <td className="montserrat-regular f-16">
                    <div className="">
                      {data?.status != "COMPLETED" &&
                      item?.status == "FAILED" ? (
                        <button
                          className="btn btn-primary"
                          onClick={() => handleDuePayment(item)}
                        >
                          Pay
                        </button>
                      ) : (
                        "-"
                      )}
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="8">{t("No Data Available")}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <SwitchPaymentModal
        show={show}
        data={data}
        setShow={setShow}
        handleClose={handleClose}
        handleSwitchMethod={handleSwitchMethod}
        method={method}
        setMethod={setMethod}
      />
    </div>
  );
};

export default withTranslation()(ViewPurchasedService);

ViewPurchasedService.propTypes = {
  t: PropTypes.any,
};

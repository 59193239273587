import Signup from "../Components/Auth/Signup";
import Otp from "../Components/Auth/OTP";
import BankDetail from "../Components/Auth/BankDetail";
import CardDetail from "../Components/Auth/CardDetail";
import SignupComplete from "../Components/Auth/SignupComplete";
import TraditionalBanking from "../Pages/Traditional-banking";
import Programmed from "../Pages/Programing";
import Forgot from "../Components/Auth/forgot";
import ResetPassword from "../Components/Auth/resetPassword";
import PurchasedServices from "../Pages/Programing/PurchasedServices";
import ViewPurchasedService from "../Pages/Programing/ViewPurchasedService";
import Transactions from "../Pages/Transactions";
import { Navigate } from "react-router-dom";
import ProfileSettings from "../Pages/Settings";
import Support from "../Pages/Support";

let isAuth = JSON.parse(localStorage.getItem("fintech-user"));

const publicRoutes = [
  { path: "/signup", component: Signup },
  { path: "/otp-verification", component: Otp },
  // { path: "/user-kyc", component: UserKyc },
  { path: "/bank-detail", component: BankDetail },
  { path: "/card-detail", component: CardDetail },
  { path: "/signup-complete", component: SignupComplete },
  // { path: "/select-purpose", component: Purpose },
  { path: "/forgot-password", component: Forgot },
  { path: "/reset-password", component: ResetPassword },
];

const authProtectedLayoutRoutes = [
  { path: "/traditional-banking", component: TraditionalBanking },
  { path: "/programmed-service", component: Programmed },
  { path: "/transactions", component: Transactions },
  { path: "/support", component: Support },
  // { path: "/service-t&c", component: InvestTC },
  { path: "/profile-settings", component: ProfileSettings },
  {
    path: "/purchased-services",
    component: PurchasedServices,
  },
  {
    path: "/purchased-services/service-detail",
    component: ViewPurchasedService,
  },
  {
    path: "/",
    component: () =>
      isAuth?.token ? (
        <Navigate to="/programmed-service" />
      ) : (
        <Navigate to="/login" />
      ),
  },
];

export { authProtectedLayoutRoutes, publicRoutes };

import React from "react";
import Modal from "react-bootstrap/Modal";
import { withTranslation } from "react-i18next";
import Form from "react-bootstrap/Form";
import { useFormik } from "formik";
import * as Yup from "yup";

const WithdrawBalModal = ({
  show,
  amount,
  handleClose,
  setAmount,
  submitWithdraw,
  t,
}) => {
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      amount: "",
      bankAccount: "",
      name: "",
      ifscCode: "",
    },
    validationSchema: Yup.object({
      amount: Yup.number()
        .required(t("Required"))
        .notOneOf([0], t("Amount must be greater than 0"))
        .min(0, t("Amount must be greater than 0")),
      name: Yup.string().required("Required"),
      ifscCode: Yup.string()
        .required(t("Required"))
        .min(8, t("Must be atleast 8 characters"))
        .max(11, t("Must be less than 11 characters")),
      bankAccount: Yup.string().required(t("Required")),
    }),
    onSubmit: async (values) => {
      submitWithdraw(values);
    },
  });
  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={handleClose}
    >
      <Modal.Header closeButton className="montserrat-bold">
        <h5>{t("Withdraw balance from your wallet")}</h5>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={validation.handleSubmit}>
          <Form.Group controlId="validationCustom01" className="mb-4">
            <Form.Label className="montserrat-bold">
              {t("Enter Amount")}
            </Form.Label>
            <Form.Control
              name="amount"
              value={validation.values.amount}
              onChange={validation.handleChange}
              type="number"
              placeholder=""
            />
            {validation.touched.amount && validation.errors.amount ? (
              <div style={{ color: "red" }}>{validation.errors.amount}</div>
            ) : null}
          </Form.Group>

          <div className="row">
            <Form.Group controlId="validationCustom01" className="mb-4 col-6">
              <Form.Label className="montserrat-bold">
                {t("Account Holder Name")}
              </Form.Label>
              <Form.Control
                name="name"
                value={validation.values.name?.replace(/[^a-zA-Z]/g, "")}
                onChange={validation.handleChange}
                type="text"
                placeholder=""
              />
              {validation.touched.name && validation.errors.name ? (
                <div style={{ color: "red" }}>{validation.errors.name}</div>
              ) : null}
            </Form.Group>
            <Form.Group controlId="validationCustom01" className="mb-4 col-6">
              <Form.Label className="montserrat-bold">
                {t("SWIFT Code")}
              </Form.Label>
              <Form.Control
                name="ifscCode"
                className="text-uppercase"
                value={validation.values.ifscCode}
                onChange={validation.handleChange}
                type="text"
                placeholder=""
              />
              {validation.touched.ifscCode && validation.errors.ifscCode ? (
                <div style={{ color: "red" }}>{validation.errors.ifscCode}</div>
              ) : null}
            </Form.Group>
          </div>
          <Form.Group controlId="validationCustom01" className="mb-4">
            <Form.Label className="montserrat-bold">
              {t("Enter Bank Account")}
            </Form.Label>
            <Form.Control
              name="bankAccount"
              value={validation.values.bankAccount?.replace(/[^0-9]/g, "")}
              onChange={validation.handleChange}
              type="text"
              placeholder=""
              minLength={8}
              maxLength={20}
            />
            {validation.touched.bankAccount && validation.errors.bankAccount ? (
              <div style={{ color: "red" }}>
                {validation.errors.bankAccount}
              </div>
            ) : null}
          </Form.Group>
          <button className="w-100 mb-3 common-btn fw-bold" type="submit">
            {t("Withdraw")}
          </button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default withTranslation()(WithdrawBalModal);

import React from "react";
import { CiSearch } from "react-icons/ci";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

const SearchFilter = ({ handleSearchClick, t }) => {
  return (
    <div className="search-filter  ms-md-auto">
      <div className="search-box  my-0  d-inline-block">
        <div className="position-relative">
          <label htmlFor="search-bar-0" className="search-label mb-0">
            <span id="search-bar-0-label" className="sr-only">
              Search this table
            </span>
            <input
              id="search-bar-0"
              type="search"
              className="form-control"
              placeholder={`${t("Search")}...`}
              style={{ borderRadius: "4px" }}
              onChange={handleSearchClick}
            />
          </label>
          <CiSearch className="bx bx-search-alt search-icon" />
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(SearchFilter);

SearchFilter.propTypes = {
  t: PropTypes.any,
};

import React from "react";
import UnlockImg from "../../Assets/Images/unlock.png";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

const Features = ({ t }) => {
  return (
    <div className="home_features_page common-margin">
      <div className="container">
        <div className="row">
          <div className="col-xl-7 col-sm-12">
            <h1>
              {t("UNLOCK THE POWER OF FINTECH BLOK")}{" "}
              <span>{t("FEATURES")}</span>
            </h1>
          </div>
          <div className="col-xl-5 col-sm-12 m-auto">
            <img src={UnlockImg} alt="unlock" />
            <p className="mt-3">
              lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(Features);

Features.propTypes = {
  t: PropTypes.any,
};

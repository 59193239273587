export const useAuth = () => {
  let isAuth = JSON.parse(localStorage.getItem("fintech-user"));
  const user = isAuth?.token;
  if (user) {
    return true;
  } else {
    return false;
  }
};

export const useVerified = () => {
  let isVerified = JSON.parse(localStorage.getItem("fintech-user"));
  const kyc = isVerified?.user?.isKYCConfirmed;
  if (kyc === "APPROVED") {
    return true;
  } else {
    return false;
  }
};

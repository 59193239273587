import React from "react";
import BuyTokenImg from "../../Assets/Images/buyToken.png";
import { FaArrowRight } from "react-icons/fa";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

const BuyTokens = ({ t }) => {
  return (
    <div className="container" id="buy-token">
      <div className="home_buy_tokens common-margin">
        <div className="d-flex">
          <img
            src={BuyTokenImg}
            alt="buy token"
            className="d-none d-sm-block"
          />
          <div className="buy_token_content common-margin">
            <h2 className="">
              {t("Buy our")} <span>{t("Tokens")}</span>
            </h2>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has
              <br /> been the industry's standard dummy text ever since the
              1500s
            </p>
          </div>
          <div className="buy_token_button">
            <button className="btn">
              {t("Buy Now")} <FaArrowRight />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(BuyTokens);

BuyTokens.propTypes = {
  t: PropTypes.any,
};

import React from "react";
import "../../Assets/Css/Auth.css";
import Logo from "../../Assets/Images/Logo.svg";
import LoginRight from "../../Assets/Images/SignupComplete.png";

import "react-phone-input-2/lib/style.css";
import { Link } from "react-router-dom";

const SignupComplete = () => {
  return (
    <div className="container mt-5">
      <div className="row  h-100 border-layout">
        <div className="col-lg-6 AuthLeft bg-white py-5">
          <img className="mx-5" src={Logo} alt="" width={280} />
          <div className=" justify-content-center align-items-center mt-5">
            <div className="d-flex justify-content-center text-center mb-5 fs-2">
              <strong>
                Welcome <br />
                to Fintech Blok
              </strong>
            </div>
            <div className="d-flex justify-content-center">
              <Link to={"/select-purpose"} state={{ width: "100%" }}>
                <button type="submit" className=" my-4 common-btn fw-bold">
                  Continue
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="col-lg-6 AuthRight p-5 d-flex justify-content-center align-items-center">
          <img src={LoginRight} alt="Login" className="img-fluid" />
        </div>
      </div>
    </div>
  );
};

export default SignupComplete;

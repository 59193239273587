import React, { useState, useEffect, useContext } from "react";
import { getFaq } from "../../services/cmsServices";
import { UserContext } from "../../context";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

const Faq = ({ t }) => {
  const [data, setData] = useState([]);
  const { language } = useContext(UserContext);
  const fetch = () => {
    // setLoader(true);
    getFaq()
      .then((res) => {
        setData(res?.data?.items);
        // setLoader(false);
      })
      .catch((err) => {
        console.log(err, "error of getAll products....");
        // setLoader(false);
      });
  };

  useEffect(() => {
    fetch();
    // eslint-disable-next-line
  }, [language]);

  return (
    <div className="container" id="faq">
      <div className="home_faq_page p-5">
        <p className="home_faq_heading">{t("Frequently asked questions")}</p>
        <h6>{t("Get the answer you need")}</h6>
        <div className="accordion mt-4 FaqSet" id="accordionExample">
          {data?.map((item, i) => (
            <div className="accordion-item" key={i}>
              <h2 className="accordion-header">
                <button
                  className="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapse${i}`}
                  aria-controls={`collapse${i}`}
                  aria-expanded={i === 0 ? "true" : "false"}
                >
                  {language === "sp" ? item?.questionSp : item?.question}
                </button>
              </h2>
              <div
                id={`collapse${i}`}
                className={`accordion-collapse collapse  ${i === 0 && "show"}`}
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  {language === "sp" ? item?.answerSp : item?.answers}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(Faq);

Faq.propTypes = {
  t: PropTypes.any,
};

import React from "react";
import Slider from "react-slick";
import { SiAmericanexpress } from "react-icons/si";
import {
  FaCcVisa,
  FaCcMastercard,
  FaCcDiscover,
  FaCcJcb,
  FaCcDinersClub,
} from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import Swal from "sweetalert2";
import Tooltip from "../Common/Tooltip";

const CardSlider = ({ data, removeAddedCardFunc }) => {
  var settings = {
    dots: true,
    infinite: false,
    autoplay: true,
    speed: 500,
    slidesToShow: 2,
    centerPadding: "20px",
    slidesToScroll: 1,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleRemoveCard = async (item, document) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You want to remove card?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#317d9d",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancel",
      confirmButtonText: "Yes, Remove it!",
    });
    if (result.isConfirmed) {
      removeAddedCardFunc(item, document);
    }
  };

  return (
    <div className="feature_slider_elememnt">
      {data?.length > 0 ? (
        <Slider {...settings}>
          {data?.map((cardItem) =>
            cardItem?.cards?.map((item, i) => (
              <div className="px-3" key={i}>
                <div className="debit-card-bg position-relative">
                  <div className="d-flex flex-column justify-content-between gap-4">
                    <span className="text-white montserrat-bold f-22 my-2">
                      {cardItem?.name}
                    </span>
                  </div>
                  <div className="d-flex justify-content-between gap-4 card-no-text align-items-center">
                    <div className="">
                      <div className="my-2">
                        <span className="text-white montserrat-bold f-16">
                          {item?.card_brand === "visa" ? (
                            <FaCcVisa size={30} />
                          ) : item?.card_brand === "mastercard" ? (
                            <FaCcMastercard size={30} />
                          ) : item?.card_brand === "amex" ? (
                            <SiAmericanexpress size={30} />
                          ) : item?.card_brand === "discover" ? (
                            <FaCcDiscover size={30} />
                          ) : item?.card_brand === "jcb" ? (
                            <FaCcJcb size={30} />
                          ) : item?.card_brand === "diners" ? (
                            <FaCcDinersClub size={30} />
                          ) : (
                            ""
                          )}
                        </span>
                        <br />
                        <span className="text-white montserrat-bold f-16">
                          {item?.number}
                        </span>
                        <br />
                        <div className="d-flex justify-content-between">
                          {/* <span className="text-white montserrat-bold f-16">
                            {cardItem?.name}
                          </span> */}
                          {/* <span className="text-white montserrat-bold f-16">
                            {addZeroPrefix(item?.cardDetails?.expire_month) +
                              " - " +
                              item?.cardDetails?.expire_year}
                          </span> */}
                        </div>
                      </div>
                    </div>
                    <div
                      onClick={() => handleRemoveCard(item, cardItem?.document)}
                      id="removeCard"
                      style={{
                        cursor: "pointer",
                        padding: "10px",
                        borderRadius: "50%",
                        background: "white",
                      }}
                    >
                      <MdDelete size={25} />
                      <Tooltip
                        placement="top"
                        target="removeCard"
                        text="Remove Card"
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </Slider>
      ) : (
        <div className="debit-card-bg w-50"></div>
      )}
    </div>
  );
};
export default CardSlider;

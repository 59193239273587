import { put, putAsForm, get, post, postAsForm } from "../helpers/apis_helper";
import appendData from "../Utils/formData";

export const updateNotificationStatus = (data) => {
  return put("/client/notificationStatusUpdate", data);
};

export const getUser = () => {
  return get("/client/me");
};

export const getNotifications = () => {
  return get("/client/getNotification");
};
export const getKycDocument = () => {
  return get("/client/getKycDocument");
};

export const verifyKyc = (data) => {
  return post("/client/verifyKyc", data);
};

export const updateProfile = (data) => {
  const formData = appendData(data);
  return putAsForm("/client/editProfile", formData);
};

export const updateProfileImage = (data) => {
  const formData = appendData(data);
  return postAsForm("/client/saveImage", formData);
};

export const changePassword = (data) => {
  return post("/client/changePassword", data);
};

export const getKycAllInfo = () => {
  return get("/client/getKycList");
};

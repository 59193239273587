import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { withTranslation } from "react-i18next";

const PlanTerminationDetailModal = ({
  show,
  handleClose,
  data,
  t,
  terminatePlan,
}) => {
  const [check, setCheck] = useState(false);
  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={handleClose}
    >
      <Modal.Header closeButton className="montserrat-bold">
        <h5> {t("Plan Termination Details")}</h5>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="row">
            <div className="col-6">
              <h6>{t("Total Amount")}</h6>
            </div>
            <div className="col-6">
              <p>${data?.totalAmount?.toFixed(2)}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <h6>{t("Final Amount to be Credited")}</h6>
            </div>
            <div className="col-6">
              <p>${data?.balanceToPlus?.toFixed(2)}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <h6>{t("Interest Amount")}</h6>
            </div>
            <div className="col-6">
              <p>${data?.interestAmount?.toFixed(2)}</p>
            </div>
          </div>

          <div className="row">
            <div className="col-6">
              <h6>{t("Penalty")}</h6>
            </div>
            <div className="col-6">
              <p>${data?.penalityAmount?.toFixed(2)}</p>
            </div>
          </div>
          <div className="d-flex gap-2 mb-4">
            <input
              onChange={(e) => setCheck(e.target.checked)}
              style={{ cursor: "pointer" }}
              type="checkbox"
              id="vehicle1"
              name="vehicle1"
              value="Bike"
            />
            <span>{t("I want to teminate this plan")}</span>
          </div>
          <div className="d-flex gap-2">
            <button
              className="w-100 mb-3 common-btn fw-bold"
              onClick={() => {
                handleClose();
              }}
            >
              {t("Cancel")}
            </button>
            <button
              disabled={!check}
              className="w-100 mb-3 common-btn fw-bold"
              onClick={() => {
                terminatePlan(data?.balanceToPlus, data?.serviceId);
              }}
            >
              {t("Terminate")}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default withTranslation()(PlanTerminationDetailModal);

import React, { useState, useContext, useEffect } from "react";
import { Nav } from "react-bootstrap";
import "./Dashboard.css";
import Cardimage from "../../Assets/Images/card.png";
import Filter from "../../Assets/Images/filtericon.png";
import Option from "../../Assets/Images/option.png";
import User from "../../Assets/Images/user.png";
import Downloadicon from "../../Assets/Images/download.png";
import Chart1 from "../../Components/charts/chart.js";
import Graph from "../../Components/charts/graph.js";
import Losschart from "../../Components/charts/lossChart.js";
import AddBalanceModal from "./AddBalanceModal.js";
import PaypalModal from "./PaypalModal.js";
import { UserContext } from "../../context";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

const Dashboard = ({ t }) => {
  const { userData, getUserData } = useContext(UserContext);
  const [activeTab, setActiveTab] = useState("deposit");
  const [show, setShow] = useState(false);
  const [amount, setAmount] = useState("");

  const handleClose = () => {
    setShow(false);
  };

  const handleTabSelect = (eventKey) => {
    setActiveTab(eventKey);
  };
  const [show1, setShow1] = useState(false);

  const handleClose1 = () => {
    setShow1(false);
    setAmount("");
  };
  useEffect(() => {
    getUserData();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <div className="container-fluid traditional-banking-page">
        <div className="col-12">
          <div className="row">
            <div className="col-lg-8 ">
              <div className="m-auto">
                <div className="dashboard-overview pb-4">
                  <div className="borderGreen p-2"></div>
                  <div className="py-2 px-3 px-lg-5">
                    <div className="d-flex justify-content-between align-items-center">
                      <span className="montserrat-bold">
                        {t("Traditional Banking")}
                      </span>
                      <div>
                        {/* <button className="common-btn2 py-2 px-3 mx-2">
                          <strong>+</strong> Card
                        </button> */}
                        <button
                          className="common-btn2 py-2 px-3"
                          onClick={() => setShow(true)}
                        >
                          <strong>+</strong> {t("Add Balance")}
                        </button>
                      </div>
                    </div>
                    <div className="mt-3">
                      <Nav
                        variant="underline"
                        defaultActiveKey="deposit"
                        onSelect={handleTabSelect}
                      >
                        <Nav.Item>
                          <Nav.Link eventKey="deposit">{t("Deposit")}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="withdraw">
                            {t("Withdraw")}
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="send">{t("Send")}</Nav.Link>
                        </Nav.Item>
                      </Nav>
                      {activeTab === "deposit" && (
                        <div className="mt-4">
                          <div className="row">
                            <div className="col-lg-6 m-auto">
                              <div className="balance-card-bg">
                                <div className="d-flex flex-column justify-content-between gap-4">
                                  <div>
                                    <div className="d-flex justify-content-between align-items-center">
                                      <span className="montserrat-bold f-24 text-white">
                                        Balance
                                      </span>
                                      <span className="text-white f-14 montserrat-bold ">
                                        Check balance
                                      </span>
                                    </div>
                                  </div>
                                  <div>
                                    <span className="f-44 montserrat-bold text-white">
                                      ${userData?.balance?.toFixed(2)}{" "}
                                    </span>
                                    <span className="text-white montserrat-bold f-18">
                                      USD
                                    </span>
                                  </div>
                                  {/* <div>
                                    <span className="text-white montserrat-bold f-14">
                                      Account No. : 523774
                                    </span>
                                  </div> */}
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 pe-0">
                              <img
                                alt="card-img"
                                src={Cardimage}
                                className="dashboard-card-img"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      {activeTab === "withdraw" && (
                        <div className="mt-4">Withdraw Content</div>
                      )}
                      {activeTab === "send" && (
                        <div className="mt-4">Send Content</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="recent-transaction-div mt-5">
                <div className="d-flex justify-content-between mb-5">
                  <div>
                    <span className="text-uppercase  montserrat-bold letter-2 f-22">
                      {t("Recent Transactions")}
                    </span>
                  </div>
                  <div>
                    <button className="filter-button montserrat-regular ">
                      <img src={Filter} alt="filter-img" /> Filter By
                    </button>
                    &nbsp;
                    <span className="montserrat-regular f-14">
                      {t("View All")}
                    </span>
                  </div>
                </div>
                <div className="custom-table mb-4">
                  <table>
                    <tbody>
                      <tr className="mb-2">
                        <td className="montserrat-regular f-16">
                          <img src={User} alt="user-img" className="user-img" />{" "}
                          Isabella
                        </td>
                        <td
                          style={{ color: "#8A979E", minWidth: "170px" }}
                          className="montserrat-regular f-15"
                        >
                          03 Feb 2024, 10:31
                        </td>
                        <td className="montserrat-regular f-16">+$32.50</td>
                        <td>
                          <button className="received-button">Received</button>
                        </td>
                        <td>
                          <button className="receipt-btn">
                            Receipt{" "}
                            <img
                              style={{ width: "13px" }}
                              alt="download-img"
                              src={Downloadicon}
                            />
                          </button>
                        </td>
                        <td>
                          <img
                            alt="option-img"
                            className="option"
                            src={Option}
                          />
                        </td>
                      </tr>
                      <tr className="mb-2">
                        <td className="montserrat-regular f-16">
                          <img alt="user-img" src={User} className="user-img" />{" "}
                          Isabella
                        </td>
                        <td
                          style={{ color: "#8A979E", minWidth: "170px" }}
                          className="montserrat-regular f-15"
                        >
                          03 Feb 2024, 10:31
                        </td>
                        <td className="montserrat-regular f-16">+$32.50</td>
                        <td>
                          <button className="received-button">Received</button>
                        </td>
                        <td>
                          <button className="receipt-btn">
                            Receipt{" "}
                            <img
                              alt="download-img"
                              style={{ width: "13px" }}
                              src={Downloadicon}
                            />
                          </button>
                        </td>
                        <td>
                          <img
                            alt="option-img"
                            className="option"
                            src={Option}
                          />
                        </td>
                      </tr>
                      <tr className="mb-2">
                        <td className="montserrat-regular f-16">
                          <img alt="user-img" src={User} className="user-img" />{" "}
                          Isabella
                        </td>
                        <td
                          style={{ color: "#8A979E", minWidth: "170px" }}
                          className="montserrat-regular f-15"
                        >
                          03 Feb 2024, 10:31
                        </td>
                        <td className="montserrat-regular f-16">+$32.50</td>
                        <td>
                          <button className="received-button">Received</button>
                        </td>
                        <td>
                          <button className="receipt-btn">
                            Receipt{" "}
                            <img
                              alt="download-img"
                              style={{ width: "13px" }}
                              src={Downloadicon}
                            />
                          </button>
                        </td>
                        <td>
                          <img
                            alt="option-img"
                            className="option"
                            src={Option}
                          />
                        </td>
                      </tr>
                      <tr className="mb-2">
                        <td className="montserrat-regular f-16">
                          <img alt="user-img" src={User} className="user-img" />{" "}
                          Isabella
                        </td>
                        <td
                          style={{ color: "#8A979E", minWidth: "170px" }}
                          className="montserrat-regular f-15"
                        >
                          03 Feb 2024, 10:31
                        </td>
                        <td className="montserrat-regular f-16">+$32.50</td>
                        <td>
                          <button className="received-button">Received</button>
                        </td>
                        <td>
                          <button className="receipt-btn">
                            Receipt{" "}
                            <img
                              alt="download-img"
                              style={{ width: "13px" }}
                              src={Downloadicon}
                            />
                          </button>
                        </td>
                        <td>
                          <img
                            alt="option-img"
                            className="option"
                            src={Option}
                          />
                        </td>
                      </tr>
                      <tr className="mb-2">
                        <td className="montserrat-regular f-16">
                          <img alt="user-img" src={User} className="user-img" />{" "}
                          Isabella
                        </td>
                        <td
                          style={{ color: "#8A979E", minWidth: "170px" }}
                          className="montserrat-regular f-15"
                        >
                          03 Feb 2024, 10:31
                        </td>
                        <td className="montserrat-regular f-16">+$32.50</td>
                        <td>
                          <button className="received-button">Received</button>
                        </td>
                        <td>
                          <button className="receipt-btn">
                            Receipt{" "}
                            <img
                              alt="download-img"
                              style={{ width: "13px" }}
                              src={Downloadicon}
                            />
                          </button>
                        </td>
                        <td>
                          <img
                            alt="option-img"
                            className="option"
                            src={Option}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="dashboard-right">
                <div className="dashboard-right-div mb-3">
                  <div className="borderGreen p-2"></div>
                  <div className="p-4">
                    <div className="d-flex justify-content-between">
                      <div>
                        <span
                          style={{ color: "#8A979E" }}
                          className="montserrat-regular"
                        >
                          Total Expenses
                        </span>
                        <br />
                        <span className="montserrat-bold">$242.58</span>
                      </div>
                      <div>
                        <button className="filter-button">Week</button>
                      </div>
                    </div>
                    <Graph />
                  </div>
                </div>
                <div className="dashboard-right-div mb-3">
                  <div className="borderGreen p-2"></div>
                  <div className="p-4">
                    <div className="d-flex justify-content-between">
                      <div>
                        <span
                          style={{ color: "#8A979E" }}
                          className="montserrat-regular"
                        >
                          Cashflow
                        </span>
                        <br />
                      </div>
                      <div>
                        <button className="filter-button">Week</button>
                      </div>
                    </div>
                    <span className="ml-5">Profit</span>
                    <Chart1 />
                    <div>
                      <span className="ml-5">Loss </span>
                      <Losschart />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddBalanceModal
        show={show}
        amount={amount}
        show1={show1}
        setShow1={setShow1}
        setAmount={setAmount}
        handleClose1={handleClose1}
        handleClose={handleClose}
      />
      <PaypalModal show={show1} amount={amount} handleClose={handleClose1} />
    </>
  );
};

export default withTranslation()(Dashboard);

Dashboard.propTypes = {
  t: PropTypes.any,
};

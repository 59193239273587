import { useState } from "react";
import "../../Assets/Css/Auth.css";
import Logo from "../../Assets/Images/Logo.svg";
import Form from "react-bootstrap/Form";
import LoginRight from "../../Assets/Images/Login.png";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Loader from "../../common/loader";
import { forgot, verifForgotOtp } from "../../services/register";
import { useFormik } from "formik";
import * as Yup from "yup";
import Backimg from "../../Assets/Images/backbutton.png";
import { successToast, errorToast } from "../../common/toaster.js";
import Otp from "./OtpModal.jsx";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { successMsgHandler } from "../../common/successMsgHandler.js";
import { errorMsgHandler } from "../../common/errorMsgHandler.js";

const ForgotPage = ({ t }) => {
  const [loader, setLoader] = useState(false);
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const handleClose = () => {
    setShow(false);
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required(t("Required")).email(t("Invalid email")),
    }),
    onSubmit: async (values) => {
      setEmail(values?.email);
      setLoader(true);
      forgot(values)
        .then((res) => {
          setShow(true);
          successToast(t(`${successMsgHandler(res?.message)}`));
          setLoader(false);
        })
        .catch((err) => {
          errorToast(t(`${errorMsgHandler(err)}`));
          setLoader(false);
        });
    },
  });

  const resend = () => {
    setLoader(true);
    forgot({ email })
      .then((res) => {
        successToast("OTP resend successfully!");
        setLoader(false);
        setShow(true);
      })
      .catch((err) => {
        errorToast(err?.response?.data?.error);
        setLoader(false);
      });
  };

  const OtpModalValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: email,
      otp: "",
    },
    validationSchema: Yup.object({
      otp: Yup.string()
        .min(5, t("Invalid OTP"))
        .max(6, t("Invalid OTP"))
        .required(t("Please check the mail and enter the OTP.")),
    }),
    onSubmit: async (values) => {
      try {
        verifForgotOtp(values)
          .then((res) => {
            successToast(t(`${successMsgHandler(res?.message)}`));
            localStorage.setItem("token-info", res?.data?.token);
            setShow(false);
            navigate("/reset-password");
          })
          .catch((err) => {
            errorToast(t(`${errorMsgHandler(err)}`));
            console.log(err, "err");
          });
      } catch (err) {}
    },
  });

  return (
    <div className="container mt-5">
      {loader ? <Loader /> : ""}
      <div>
        <div className="row   h-100 border-layout">
          <div className="col-lg-6 AuthLeft bg-white">
            <img
              src={Logo}
              style={{ cursor: "pointer", maxWidth: "280px" }}
              onClick={() => navigate("/")}
              alt="logo"
              className="mx-5 py-5"
            />
            <div className="px-5 ">
              <Link to="/login">
                <img
                  alt="background"
                  src={Backimg}
                  style={{ width: "89px", height: "32px", cursor: "pointer" }}
                />
              </Link>
            </div>
            <div className="d-flex justify-content-center mb-5 fs-2 align-items-center">
              <strong className="montserrat-bold">
                {t("Forgot Password")}
              </strong>
            </div>
            <Form onSubmit={validation.handleSubmit} className="px-5">
              <Form.Group controlId="validationCustom01">
                <Form.Label className="montserrat-bold">
                  {t("Email ID")}
                </Form.Label>
                <Form.Control
                  name="email"
                  value={validation.values.email}
                  onChange={validation.handleChange}
                  type="text"
                  placeholder={t("Email Address")}
                />
                {validation.touched.email && validation.errors.email ? (
                  <div style={{ color: "red" }}>{validation.errors.email}</div>
                ) : null}
              </Form.Group>

              <button type="submit" className="w-100 my-5 common-btn fw-bold">
                {t("Submit")}
              </button>
            </Form>
          </div>
          <div className="col-lg-6 AuthRight p-5 d-flex justify-content-center align-items-center">
            <img
              src={LoginRight}
              style={{ width: "100%" }}
              alt="Login"
              className=""
            />
          </div>
        </div>
      </div>
      <Otp
        email={email}
        show={show}
        resend={resend}
        handleClose={handleClose}
        validation={OtpModalValidation}
      />
    </div>
  );
};

export default withTranslation()(ForgotPage);

ForgotPage.propTypes = {
  t: PropTypes.any,
};
